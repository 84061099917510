import { InputType, IRegisterFormState } from '../interfaces';
import { EMAIL_REGEX } from '../../common/constants';
import { MainEarner } from '../../../generated';

const phonePrefixesArr: string[] = [
  '+49',
  '+43',
  '+41',
  '+34',
  '+39',
  '+45',
  '+48',
  '+420',
  '+423',
  '+352',
  '+33',
  '+32',
  '+31',
  '+46',
  '+61',
  '+1',
  '+86',
  '+81',
  '+91',
  '+44',
  '+55',
  '+7',
  '+82',
  '+52',
  '+62',
  '+966',
  '+90',
  '+886',
  '+66',
];

if (
  process.env.REACT_APP_WEBAPP_ENV &&
  process.env.REACT_APP_WEBAPP_ENV === 'development'
) {
  phonePrefixesArr.push(...['+359', '+380', '+387']);
}
export const phonePrefixes: string[] = [...phonePrefixesArr];

export const REGISTER_FORM_KEYS = {
  NAME: 'name',
  SURNAME: 'surname',
  PHONE: 'phone',
  PHONE_PREFIX: 'phonePrefix',
  EMAIL: 'email',
  HOUSEHOLD_NET_INCOME: 'householdNetIncome',
  AGE: 'age',
  MAIN_EARNER: 'mainEarner',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
  TERMS_AND_CONDITIONS_FINANCING: 'termsAndConditionsFinancing',
  TERMS_AND_CONDITIONS_FINANCING_2: 'termsAndConditionsFinancing2',
  COMMUNICATION_CONSENT: 'communicationConsent',
  CREATE_ACCOUNT: 'createAccount',
  CITY: 'city',
  STREET: 'street',
  NUMBER: 'number',
};

export const EDIT_PROFILE_FORM_KEYS = {
  NAME: 'name',
  SURNAME: 'surname',
  PHONE: 'phone',
  PHONE_PREFIX: 'phonePrefix',
  EMAIL: 'email',
  OLD_PASSWORD: 'oldPassword',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
};

export const REGISTRATION_FORM_STATE: IRegisterFormState[][] = [
  [
    {
      name: 'name' as const,
      type: InputType.text,
      label: 'register.form.input.label.name',
      placeholder: 'register.form.input.placeholder.name',
      rules: { required: 'register.input.error.required' },
    },
    {
      name: 'surname' as const,
      type: InputType.text,
      label: 'register.form.input.label.surname',
      placeholder: 'register.form.input.placeholder.surname',
      rules: { required: 'register.input.error.required' },
    },
  ],
  [
    {
      name: 'phone' as const,
      label: 'register.form.input.label.phone',
      placeholder: 'register.form.input.placeholder.phone',
      selectWidth: '87px',
      hasSelect: true,
      selectValue: phonePrefixes[0],
      selectOptions: phonePrefixes,
      selectName: 'phonePrefix',
      type: InputType.number,
      rules: {
        required: 'register.input.error.required',
        valueAsNumber: true,
      },
    },
    {
      name: 'email' as const,
      label: 'register.form.input.label.email',
      placeholder: 'register.form.input.placeholder.email',
      type: InputType.text,
      rules: {
        required: 'register.input.error.required',
        pattern: EMAIL_REGEX,
      },
    },
  ],
  [
    {
      type: InputType.checkbox,
      name: 'privacy' as const,
      rules: {
        required: 'register.input.error.required',
      },
      checkboxLabel: {
        online: 'register.form.input.label.privacy-online',
        personal: 'register.form.input.label.privacy-personal',
      },
    },
  ],
  [
    {
      type: InputType.checkbox,
      name: 'autoRatingAccount' as const,
      label: 'register.form.input.label.rating-account',
      checkboxLabel: {
        online: 'register.form.input.label.rating-account-online',
        personal: 'register.form.input.label.rating-account-personal',
      },
    },
  ],
];

export const mainEarnerOptions = [
  {
    // Angestellte/r
    label: 'register.form.input.label.main-earner.options.employees',
    value: MainEarner.Employee,
  },
  {
    // Beamte/r
    label: 'register.form.input.label.main-earner.options.official',
    value: MainEarner.Official,
  },
  {
    // Arbeiter/in
    label: 'register.form.input.label.main-earner.options.worker',
    value: MainEarner.Worker,
  },
  {
    // Freiberufler/in
    label: 'register.form.input.label.main-earner.options.freelancer',
    value: MainEarner.Freelancer,
  },
  {
    // Selbständige/r
    label: 'register.form.input.label.main-earner.options.self-employed',
    value: MainEarner.SelfEmployed,
  },
  {
    // Rentner/in
    label: 'register.form.input.label.main-earner.options.pensioner',
    value: MainEarner.Pensioner,
  },
  // {
  //   // In Elternzeit
  //   label: 'register.form.input.label.main-earner.options.on-parental-leave',
  //   value: MainEarner.OnParentalLeave,
  // },
  // {
  //   // Selbstständige/r (bilanzierend)
  //   label:
  //     'register.form.input.label.main-earner.options.self-employed-person-accounting',
  //   value: MainEarner.SelfEmployedPersonAccounting,
  // },
  // {
  //   // Geschäftsf. Gesellschafter/in
  //   label: 'register.form.input.label.main-earner.options.business-partner',
  //   value: MainEarner.BusinessPartner,
  // },
  // {
  //   // Selbstständige/r (nicht bil.)
  //   label:
  //     'register.form.input.label.main-earner.options.self-employed-person-not-qualified',
  //   value: MainEarner.SelfEmployedPersonNotQualified,
  // },
  // {
  //   // Soldat/in
  //   label: 'register.form.input.label.main-earner.options.soldier',
  //   value: MainEarner.Soldier,
  // },
  // {
  //   // Privatier/e
  //   label: 'register.form.input.label.main-earner.options.privateer',
  //   value: MainEarner.Privateer,
  // },
  {
    // Hausmann/-frau
    label: 'register.form.input.label.main-earner.options.house-wife',
    value: MainEarner.HouseWife,
  },
  // {
  //   // Student/in
  //   label: 'register.form.input.label.main-earner.options.student',
  //   value: MainEarner.Student,
  // },
  {
    // Arbeitslose/r
    label: 'register.form.input.label.main-earner.options.unemployed',
    value: MainEarner.Unemployed,
  },
  // {
  //   // Vorstand/Vorständin
  //   label:
  //     'register.form.input.label.main-earner.options.board-directors-board-member',
  //   value: MainEarner.BoardDirectorsBoardMember,
  // },
  // {
  //   // Geringfügig Beschäftigte/r
  //   label: 'register.form.input.label.main-earner.options.marginal-employee',
  //   value: MainEarner.MarginalEmployee,
  // },
];
