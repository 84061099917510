import { RegisterOptions } from 'react-hook-form';
import { PropertyCode } from '../../../../generated';

export const FORM_STEPS = 3;

export const SEARCH_PROFILE_FORM_KEYS = {
  TITLE: 'title',
  CODE: 'code',
  POST_CODE: 'postCode',
  LOCALITY: 'locality',
  NEIGHBORHOOD: 'neighborhood',
  REGION: 'region',
  CITY: 'city',
  PLACE_NAME: 'placeName',
  RADIUS: 'radius',
  PURCHASE_PRICE_MIN: 'purchasePriceMin',
  PURCHASE_PRICE_MAX: 'purchasePriceMax',
  LIVING_AREA_MIN: 'livingAreaMin',
  LIVING_AREA_MAX: 'livingAreaMax',
  LAND_AREA_MIN: 'landAreaMin',
  LAND_AREA_MAX: 'landAreaMax',
  NUMBER_OF_ROOMS_MIN: 'numberOfRoomsMin',
  NUMBER_OF_ROOMS_MAX: 'numberOfRoomsMax',
  NUMBER_OF_BATHROOMS_MIN: 'numberOfBathroomsMin',
  NUMBER_OF_BATHROOMS_MAX: 'numberOfBathroomsMax',
};

export const SEARCH_PROFILE_NOTIFICATIONS_FORM_KEYS = {
  NEW_PROPERTY_NOTIFICATION: 'isNotifyNewProperties',
  PRICE_CHANGE_NOTIFICATION: 'isNotifyPriceChange',
  NEW_PROPERTY_SUMMARY_NOTIFICATION: 'isNotifyNewPropertiesSummary',
};

type SearchCriteriaType = {
  typeName: string;
  nameMin?: string;
  nameMax?: string;
  label: string;
  subLabel: string;
  availableFor: string[];
  defaultValues?: {
    min: number | string;
    max: number | string;
  };
  placeholderMin?: string;
  placeholderMax?: string;
  rules: {
    required: string;
    min: {
      value: number | string;
      message: string;
    };
    max: {
      value: number | string;
      message: string;
    };
    valueAsNumber: boolean;
    setValueAs: RegisterOptions['setValueAs'];
  };
  inputAdornment?: string;
};

export const SEARCH_CRITERIA: SearchCriteriaType[] = [
  {
    typeName: 'Kaufpreis',
    availableFor: [
      PropertyCode.Apartment,
      PropertyCode.House,
      PropertyCode.MultiFamilyHouse,
      PropertyCode.Plot,
    ],
    defaultValues: {
      min: '400000',
      max: '1.500000',
    },
    nameMin: SEARCH_PROFILE_FORM_KEYS.PURCHASE_PRICE_MIN,
    nameMax: SEARCH_PROFILE_FORM_KEYS.PURCHASE_PRICE_MAX,
    label: 'search-profile.form.second-step.purchase-price',
    subLabel: '(1 - 15.000.000)',
    placeholderMin:
      'search-profile.form.second-step.purchase-price-min-placeholder',
    placeholderMax:
      'search-profile.form.second-step.purchase-price-max-placeholder',
    rules: {
      required: 'register.input.error.required',
      min: {
        value: '1',
        message: 'search-profile.form.second-step.purchase-price-min-error',
      },
      max: {
        value: '1.500.000',
        message: 'search-profile.form.second-step.purchase-price-max-error',
      },
      valueAsNumber: false,
      setValueAs: (v) => v,
    },
    inputAdornment: '€',
  },
  {
    typeName: 'Wohnfläche',
    availableFor: [
      PropertyCode.Apartment,
      PropertyCode.House,
      PropertyCode.MultiFamilyHouse,
    ],
    defaultValues: {
      min: 50,
      max: 200,
    },
    nameMin: SEARCH_PROFILE_FORM_KEYS.LIVING_AREA_MIN,
    nameMax: SEARCH_PROFILE_FORM_KEYS.LIVING_AREA_MAX,
    label: 'search-profile.form.second-step.living-space',
    subLabel: '(1 - 800)',
    placeholderMin:
      'search-profile.form.second-step.living-area-min-placeholder',
    placeholderMax:
      'search-profile.form.second-step.living-area-max-placeholder',
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1,
        message: 'search-profile.form.second-step.living-area-min-error',
      },
      max: {
        value: 800,
        message: 'search-profile.form.second-step.living-area-max-error',
      },
      valueAsNumber: true,
      setValueAs: (v) => Number.parseInt(v, 10),
    },
    inputAdornment: 'm²',
  },
  {
    typeName: 'Grundstücksfläche',
    availableFor: [PropertyCode.Plot],
    defaultValues: {
      min: 500,
      max: 2000,
    },
    nameMin: SEARCH_PROFILE_FORM_KEYS.LAND_AREA_MIN,
    nameMax: SEARCH_PROFILE_FORM_KEYS.LAND_AREA_MAX,
    label: 'search-profile.form.second-step.land-area',
    subLabel: '(1 - 5000)',
    placeholderMin: 'search-profile.form.second-step.land-area-min-placeholder',
    placeholderMax: 'search-profile.form.second-step.land-area-max-placeholder',
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1,
        message: 'search-profile.form.second-step.land-area-min-error',
      },
      max: {
        value: 5000,
        message: 'search-profile.form.second-step.land-area-max-error',
      },
      valueAsNumber: true,
      setValueAs: (v) => Number.parseInt(v, 10),
    },
    inputAdornment: 'm²',
  },
  {
    typeName: 'Zimmer',
    availableFor: [PropertyCode.Apartment, PropertyCode.House],
    defaultValues: {
      min: 1,
      max: 5,
    },
    nameMin: SEARCH_PROFILE_FORM_KEYS.NUMBER_OF_ROOMS_MIN,
    nameMax: SEARCH_PROFILE_FORM_KEYS.NUMBER_OF_ROOMS_MAX,
    label: 'search-profile.form.second-step.room',
    subLabel: '(1 - 40)',
    placeholderMin:
      'search-profile.form.second-step.number-of-rooms-min-placeholder',
    placeholderMax:
      'search-profile.form.second-step.number-of-rooms-max-placeholder',
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1,
        message: 'search-profile.form.second-step.number-of-rooms-min-error',
      },
      max: {
        value: 40,
        message: 'search-profile.form.second-step.number-of-rooms-max-error',
      },
      valueAsNumber: true,
      setValueAs: (v) => Number.parseInt(v, 10),
    },
  },
  {
    typeName: 'Badezimmer',
    availableFor: [PropertyCode.Apartment, PropertyCode.House],
    defaultValues: {
      min: 1,
      max: 5,
    },
    nameMin: SEARCH_PROFILE_FORM_KEYS.NUMBER_OF_BATHROOMS_MIN,
    nameMax: SEARCH_PROFILE_FORM_KEYS.NUMBER_OF_BATHROOMS_MAX,
    label: 'search-profile.form.second-step.bathroom',
    subLabel: '(1 - 40)',
    placeholderMin:
      'search-profile.form.second-step.number-of-bathrooms-min-placeholder',
    placeholderMax:
      'search-profile.form.second-step.number-of-bathrooms-max-placeholder',
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1,
        message:
          'search-profile.form.second-step.number-of-bathrooms-min-error',
      },
      max: {
        value: 40,
        message:
          'search-profile.form.second-step.number-of-bathrooms-max-error',
      },
      valueAsNumber: true,
      setValueAs: (v) => Number.parseInt(v, 10),
    },
  },
];

export const REDESIGNED_SEARCH_CRITERIA: SearchCriteriaType[] = [
  {
    typeName: 'Kaufpreis',
    availableFor: [
      PropertyCode.Apartment,
      PropertyCode.House,
      PropertyCode.MultiFamilyHouse,
      PropertyCode.Plot,
    ],
    nameMax: SEARCH_PROFILE_FORM_KEYS.PURCHASE_PRICE_MAX,
    label: 'search-profile.form.second-step.purchase-price',
    subLabel: '(1 - 15.000.000)',
    placeholderMax: 'Kaufpreis bis',
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1,
        message: 'search-profile.form.second-step.purchase-price-min-error',
      },
      max: {
        value: 15_000_000,
        message: 'search-profile.form.second-step.purchase-price-max-error',
      },
      valueAsNumber: true,
      setValueAs: (v) => Number.parseInt(v, 10),
    },
    inputAdornment: '€',
  },
  {
    typeName: 'Wohnfläche',
    availableFor: [
      PropertyCode.Apartment,
      PropertyCode.House,
      PropertyCode.MultiFamilyHouse,
    ],
    nameMin: SEARCH_PROFILE_FORM_KEYS.LIVING_AREA_MIN,
    label: 'search-profile.form.second-step.living-space',
    subLabel: '(1 - 800)',
    placeholderMin: 'Wohnfläche ab',
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1,
        message: 'search-profile.form.second-step.living-area-min-error',
      },
      max: {
        value: 800,
        message: 'search-profile.form.second-step.living-area-max-error',
      },
      valueAsNumber: true,
      setValueAs: (v) => Number.parseInt(v, 10),
    },
    inputAdornment: 'm²',
  },
  {
    typeName: 'Grundstücksfläche',
    availableFor: [PropertyCode.Plot],
    nameMin: SEARCH_PROFILE_FORM_KEYS.LAND_AREA_MIN,
    label: 'search-profile.form.second-step.land-area',
    subLabel: '(1 - 5000)',
    placeholderMin: 'Grundstücksfläche ab',
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1,
        message: 'search-profile.form.second-step.land-area-min-error',
      },
      max: {
        value: 5000,
        message: 'search-profile.form.second-step.land-area-max-error',
      },
      valueAsNumber: true,
      setValueAs: (v) => Number.parseInt(v, 10),
    },
    inputAdornment: 'm²',
  },
  {
    typeName: 'Zimmer',
    availableFor: [PropertyCode.Apartment, PropertyCode.House],
    nameMin: SEARCH_PROFILE_FORM_KEYS.NUMBER_OF_ROOMS_MIN,
    label: 'search-profile.form.second-step.room',
    subLabel: '(1 - 40)',
    placeholderMin: 'Zimmer ab',
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1,
        message: 'search-profile.form.second-step.number-of-rooms-min-error',
      },
      max: {
        value: 40,
        message: 'search-profile.form.second-step.number-of-rooms-max-error',
      },
      valueAsNumber: true,
      setValueAs: (v) => Number.parseInt(v, 10),
    },
  },
  {
    typeName: 'Badezimmer',
    availableFor: [PropertyCode.Apartment, PropertyCode.House],
    nameMin: SEARCH_PROFILE_FORM_KEYS.NUMBER_OF_BATHROOMS_MIN,
    label: 'search-profile.form.second-step.bathroom',
    subLabel: '(1 - 40)',
    placeholderMin: 'Badezimmer ab',
    rules: {
      required: 'register.input.error.required',
      min: {
        value: 1,
        message:
          'search-profile.form.second-step.number-of-bathrooms-min-error',
      },
      max: {
        value: 40,
        message:
          'search-profile.form.second-step.number-of-bathrooms-max-error',
      },
      valueAsNumber: true,
      setValueAs: (v) => Number.parseInt(v, 10),
    },
  },
];
