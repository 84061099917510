import { useCallback } from 'react';
import { batch } from 'react-redux';

import { ISearchProfileForm } from '../interface';
import {
  clearErrors,
  resetSPC,
  setErrors,
  toggleLoading,
  updateFormStateAction,
} from '../redux/searchProfileSlice';
import { toggleLoading as toggleLoadingAuth } from '../../../auth/redux/authSlice';
import { parseSearchProfileInput } from '../../utils/parse-search-profile-input';
import { siteMap } from '../../../../routes/site-map';
import { FormError } from '../../interface';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import { useCreateSP } from './useCreateSP';

type TReturn = {
  onSubmitSPForm: (
    formData: ISearchProfileForm,
    ownerId?: string
  ) => Promise<void>;
};

const useSubmitFormSP = (): TReturn => {
  const dispatch = useAppDispatch();
  const lngHPush = useLngHistoryPush();

  const selectedAddress = useAppSelector(
    (state) => state.searchProfile.selectedAddress
  );
  const userInput = useAppSelector((state) => state.searchProfile.userInput);
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const { onCreateSearchProfile } = useCreateSP();

  const onSubmitSPForm = useCallback(
    async (formData: ISearchProfileForm, ownerId?: string): Promise<void> => {
      if (selectedAddress && formData) {
        batch(() => {
          dispatch(toggleLoading(true));
          dispatch(toggleLoadingAuth(true));
          dispatch(clearErrors());
          dispatch(updateFormStateAction(formData));
        });

        if (ownerId) {
          try {
            const input = parseSearchProfileInput(
              userInput as ISearchProfileForm,
              selectedAddress,
              ownerId
            );

            const searchProfile = await onCreateSearchProfile({
              spInput: input,
              ownerId,
            });
            dispatch(resetSPC());

            if (isIframe) {
              window.open(
                `${window.location.origin}${siteMap.SearchProfilePage.pathWithoutParams}/${searchProfile.createSearchProfile?._id}`,
                '_blank',
                'noopener,noreferrer'
              );
            } else if (searchProfile.createSearchProfile?._id) {
              lngHPush(
                `${siteMap.SearchProfilePage.pathWithoutParams}/${searchProfile.createSearchProfile?._id}`
              );
            }
          } catch (error: unknown) {
            batch(() => {
              dispatch(toggleLoading(false));
              dispatch(toggleLoadingAuth(false));
              dispatch(setErrors(error as FormError));
            });
          } finally {
            dispatch(toggleLoading(false));
            dispatch(toggleLoadingAuth(false));
          }
        }
      }
    },
    [
      selectedAddress,
      dispatch,
      userInput,
      onCreateSearchProfile,
      isIframe,
      lngHPush,
    ]
  );
  return { onSubmitSPForm };
};

export { useSubmitFormSP };
