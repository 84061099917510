import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature } from '../../../../services/mapbox/interfaces';
import { FormError } from '../../interface';
import { ISearchProfileForm } from '../interface';

interface IState {
  isSPCOpen: boolean;
  isRegisterFormOpen: boolean;
  isEditSearchProfileModalOpen: boolean;
  isNotificationModalOpen: boolean;
  isLoginFormOpen: boolean;
  isLoading: boolean;
  activeStep: number;
  errors?: FormError;
  userInput?: Partial<ISearchProfileForm>;
  isRedirect?: boolean;
  selectedAddress?: Feature;
  selectedCountryCode?: string;
  relatedCountry: string;
  regionalBrokerId?: string;
}

const initialState: IState = {
  isSPCOpen: false,
  isEditSearchProfileModalOpen: false,
  isNotificationModalOpen: false,
  isRegisterFormOpen: false,
  isLoginFormOpen: false,
  isLoading: false,
  relatedCountry: 'germany',
  activeStep: 0,
  isRedirect: false,
};

export const searchProfileSlice = createSlice({
  name: 'searchProfile',
  initialState,
  reducers: {
    openSPCAction(state) {
      state.isSPCOpen = true;
    },
    closeSPCAction(state) {
      state.isSPCOpen = false;
      state.isRegisterFormOpen = false;
      state.isLoginFormOpen = false;
      state.activeStep = 0;
      state.selectedCountryCode = '';
    },
    resetSPC() {
      return { ...initialState };
    },
    nextStepAction(state) {
      state.activeStep += 1;
    },
    prevStepAction(state) {
      state.activeStep -= 1;
    },
    updateFormStateAction(
      state,
      action: PayloadAction<Partial<ISearchProfileForm> | undefined>
    ) {
      state.userInput = {
        ...state.userInput,
        ...action.payload,
      };
    },
    setSelectedAddressAction(state, action: PayloadAction<Feature>) {
      state.selectedAddress = action.payload;
    },
    setRedirect(state, action: PayloadAction<boolean>) {
      state.isRedirect = action.payload;
    },
    setSelectedCountryCode(state, action: PayloadAction<string>) {
      state.selectedCountryCode = action.payload;
    },
    toggleIsEditSearchProfileModalOpen(state, action: PayloadAction<boolean>) {
      state.isEditSearchProfileModalOpen = action.payload;
    },
    toggleIsNotificationModalOpen(state, action: PayloadAction<boolean>) {
      state.isNotificationModalOpen = action.payload;
    },
    toggleRegisterForm(state, action: PayloadAction<boolean>) {
      state.isRegisterFormOpen = action.payload;
    },
    toggleLoginForm(state, action: PayloadAction<boolean>) {
      state.isLoginFormOpen = action.payload;
    },
    toggleLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setErrors(state, action: PayloadAction<FormError>) {
      state.errors = action.payload;
    },
    clearErrors(state) {
      delete state.errors;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openSPCAction,
  closeSPCAction,
  nextStepAction,
  prevStepAction,
  updateFormStateAction,
  setRedirect,
  setSelectedAddressAction,
  setSelectedCountryCode,
  toggleLoading,
  toggleIsNotificationModalOpen,
  toggleIsEditSearchProfileModalOpen,
  toggleLoginForm,
  toggleRegisterForm,
  setErrors,
  clearErrors,
  resetSPC,
} = searchProfileSlice.actions;

export default searchProfileSlice.reducer;
