import 'mapbox-gl/dist/mapbox-gl.css';

import mapPin from 'assets/svg/map-pin.svg';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';

import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import { CommuteTime } from '../../../../../map/components/commute-time';
import { Legend } from '../../../../../map/components/legend';
import { Pins } from '../../../../../map/components/pins';
import { ReachabilityLegend } from '../../../../../map/components/reachability-legend';
import {
  Container,
  ControlsContainer,
  MarkerImg,
  StyledPopup,
} from './mapbox-styles';
import { resetPopup, setPopup } from 'modules/map/redux/mapSlice';
import { useExposeDetailsHeadInfo } from '../../hooks/useExposeDetailsQueries';
import { MapboxGeoLocateController } from './mapbox-geolocate-controller';

const MapboxExposeDetailsBase: FC = () => {
  const viewport = useAppSelector((state) => state.exposeDetails.map.viewport);
  const settings = useAppSelector((state) => state.exposeDetails.map.settings);
  const markers = useAppSelector((state) => state.exposeDetails.map.markers);
  const { popup } = useAppSelector((state) => state.map);
  const dispatch = useAppDispatch();
  const {
    headInfo: { fullAddress },
  } = useExposeDetailsHeadInfo();

  const { showLegend, showReachabilityLegend, commuteTimeCoordinates } =
    useAppSelector((state) => state.dynamicMapUtils);
  const isReachabilityTab = useAppSelector(
    (state) => state.dynamicMapUtils.isReachabilityTab
  );

  const isMobileSize = useIsMobileSize();

  const { longitude, latitude } = viewport;

  const [innerViewport, setInnerViewport] =
    useState<Record<string, unknown>>(viewport);

  const onViewportChange = (newViewport: Record<string, unknown>) => {
    setInnerViewport(newViewport);
  };

  useEffect(() => {
    setInnerViewport(viewport);
  }, [viewport]);

  const mapStyle = `mapbox://styles/mapbox/${
    isReachabilityTab && !commuteTimeCoordinates
      ? 'navigation-day-v1'
      : 'light-v9'
  }?optimize=true`;

  const mapboxContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mapboxContainerRef.current) {
      return () => {};
    }

    const mapContainer = mapboxContainerRef.current;

    const wheelHandler = (e: Event) => {
      const wheelEvent = e as WheelEvent;

      if (wheelEvent.ctrlKey || wheelEvent.metaKey || wheelEvent.altKey) {
        return;
      }

      e.stopPropagation();
    };

    mapContainer.addEventListener('wheel', wheelHandler, true);

    return () => {
      mapContainer.removeEventListener('wheel', wheelHandler);
    };
  });

  const handleMouseEnter = useCallback(() => {
    dispatch(
      setPopup({
        latitude: (latitude as number) || 0,
        longitude: (longitude as number) || 0,
        info: fullAddress,
      })
    );
  }, [dispatch, latitude, longitude, fullAddress]);

  const handleMouseLeave = useCallback(() => {
    dispatch(resetPopup());
  }, [dispatch]);

  useEffect(() => {
    console.log(fullAddress);
  }, [fullAddress]);

  return (
    <Container ref={mapboxContainerRef}>
      {!isMobileSize && showLegend && <Legend />}
      {showReachabilityLegend && <ReachabilityLegend />}
      <ReactMapGL
        width="100%"
        height={isMobileSize ? '160px' : '640px'}
        {...innerViewport}
        {...settings}
        doubleClickZoom={false}
        getCursor={() => 'default'}
        onViewportChange={onViewportChange}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY ?? ''}
        mapStyle={mapStyle}
        asyncRender
        style={{ color: 'transparent' }}
      >
        <Pins markers={markers} />
        <ControlsContainer>
          <MapboxGeoLocateController />
          <NavigationControl />
        </ControlsContainer>
        <Marker
          longitude={(longitude as number) || 0}
          latitude={(latitude as number) || 0}
        >
          <MarkerImg
            src={mapPin}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </Marker>
        {commuteTimeCoordinates && (
          <CommuteTime commuteTimeCoordinates={commuteTimeCoordinates} />
        )}
        {popup?.info && (
          <StyledPopup
            latitude={popup.latitude}
            longitude={popup.longitude}
            anchor="bottom"
            closeButton={false}
            offsetTop={-32}
          >
            {popup.info}
          </StyledPopup>
        )}
      </ReactMapGL>
    </Container>
  );
};

const MapboxExposeDetails = memo(MapboxExposeDetailsBase);

export { MapboxExposeDetails };
