import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddCircle } from 'assets/streamline-light/interface-essential/remove-add/add-circle.svg';
import { ReactComponent as BusinessDealMen } from 'assets/streamline-light/business-products/business-deals/business-deal-men-cash-1.svg';
import { siteMap } from "../../../../../../../routes/site-map";

import { PrimaryCTAButton } from '../../../../../../common/components/ui/buttons';
import LngLink from "../../../../../../localization/lng-link";
import { useFormOpenSP } from '../../../../hooks/useFormOpenSP';

const CTAButtons: FC = () => {
  const { t } = useTranslation();
  const { onFormOpenSP } = useFormOpenSP({});

  return (
    <>
      <PrimaryCTAButton
        id="create-search-profile"
        isFirst
        icon={AddCircle}
        title={t('buyer-landing-page.primary-button.financing-advice.label')}
        description={t(
          'buyer-landing-page.primary-button.financing-advice.description'
        )}
        onClick={onFormOpenSP}
      />
      <LngLink
        absolute
        isOpenNewPage
        to={siteMap.FinancingLandingPage.path}
      >
        <PrimaryCTAButton
          id="create-financing-request"
          isLast
          skipBorder
          icon={BusinessDealMen}
          title={t('buyer-landing-page.primary-button.search-request.label')}
          description={t(
            'buyer-landing-page.primary-button.search-request.description'
          )}
          onClick={() => {}}
        />
      </LngLink>
    </>
  );
};

export { CTAButtons };
