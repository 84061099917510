import { useCallback } from 'react';
import { FlyToInterpolator } from 'react-map-gl';
import { easeCubic } from 'd3-ease';
import { batch } from 'react-redux';

import {
  Austria,
  Germany,
  Switzerland,
} from '../../../map/constants/points-of-interest';
import {
  changeViewport,
  setMarkers,
  setSettings,
} from '../../../map/redux/mapSlice';
import { openSPCAction } from '../redux/searchProfileSlice';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { defaultMapSettings } from '../../../map/constants';

type IProps = {
  cb?: () => void;
};

type ISPFormOpen = {
  onFormOpenSP: () => void;
};

export const useFormOpenSP = ({ cb }: IProps): ISPFormOpen => {
  const relatedCountry = useAppSelector(
    (state) => state.searchProfile.relatedCountry
  );
  const dispatch = useAppDispatch();

  const onFormOpenSP = useCallback(() => {
    let countryCoordinates = Germany;
    switch (relatedCountry && relatedCountry.toLowerCase()) {
      case 'germany':
        countryCoordinates = Germany;
        break;
      case 'austria':
        countryCoordinates = Austria;
        break;
      case 'switzerland':
        countryCoordinates = Switzerland;
        break;
      default:
        countryCoordinates = Germany;
    }
    batch(() => {
      dispatch(
        changeViewport({
          ...countryCoordinates,
          transitionDuration: 1000,
          transitionInterpolator: new FlyToInterpolator({
            speed: 0.6,
            curve: 1,
          }),
          transitionEasing: easeCubic,
        })
      );
      dispatch(setMarkers([]));
      dispatch(setSettings(defaultMapSettings));
      dispatch(openSPCAction());
    });
    if (cb) {
      cb();
    }
  }, [cb, dispatch, relatedCountry]);

  return {
    onFormOpenSP,
  };
};
