import { useAppSelector } from '../../../../common/hooks';
import {
  useExposeDetailsQuery,
  useSuggestedPropertyQuery,
} from '../../../../../generated';
import { filterEnergyEquipment } from '../../../utils/filterEnergyEquipment';

export const useExposeDetailsEnergyCertificate = () => {
  const propertyId = useAppSelector(
    (state) => state.exposeDetails.selectedPropertyId
  );
  const { energyCertificateData, isLoading, error, isSuccess } =
    useExposeDetailsQuery(
      {
        id: propertyId,
      },
      {
        selectFromResult: ({ data, ...rest }) => {
          const {
            finalEnergyDemand,
            energyCertificateValidUntil,
            energyEfficiencyClass,
            energySource,
            energyCertificate: energyCertificateCode,
          } = data?.exposeDetails?.propertyDetails?.energyCertificate || {};

          return {
            energyCertificateData: {
              finalEnergyDemand,
              energyCertificateValidUntil,
              energyEfficiencyClass,
              energySource: energySource?.toLowerCase(),
              energyCertificate: energyCertificateCode?.toLowerCase(),
            },
            ...rest,
          };
        },
      }
    );

  return { energyCertificateData, isLoading, error, isSuccess };
};

export const useExposeDetailsEnergyEquipment = () => {
  const propertyId = useAppSelector(
    (state) => state.exposeDetails.selectedPropertyId
  );
  const {
    energyEquipmentData: energyEquipment,
    isLoading,
    error,
    isSuccess,
  } = useExposeDetailsQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, ...rest }) => {
        const energyEquipmentData =
          data?.exposeDetails?.propertyDetails?.energyEquipment;

        const { typeOfFiring, typeOfHeating } =
          filterEnergyEquipment(energyEquipmentData);

        return {
          energyEquipmentData: { typeOfFiring, typeOfHeating },
          ...rest,
        };
      },
    }
  );

  return { energyEquipment, isLoading, error, isSuccess };
};

export const useExposeDetailsHeadInfo = () => {
  const selectedPropertyId = useAppSelector(
    (state) => state.exposeDetails.selectedPropertyId
  );
  const { headInfo, isLoading, error, isSuccess } = useExposeDetailsQuery(
    {
      id: selectedPropertyId,
    },
    {
      selectFromResult: ({ data, ...rest }) => {
        const {
          title,
          price,
          currency,
          livingArea,
          address,
          externalId,
          propertyId,
        } = data?.exposeDetails?.propertyDetails || {};

        const { provision } = data?.exposeDetails || {};

        // const { provision } =
        //   data?.exposeDetails?.propertyDetails?.equipment || {};

        // get provision value
        // let provisionValue = '';
        // provision?.split(' ')?.forEach((item, i, arr) => {
        //   if (arr[i + 1]?.includes('%')) {
        //     provisionValue = arr[i] + arr[i + 1];
        //   }
        // });

        let pricePerSqm;
        if (price && livingArea) {
          pricePerSqm = Number.parseFloat(
            (price / livingArea).toFixed(2).toString()
          );
        }

        const fullAddress = `${address?.street} ${address?.houseNumber}, ${address?.postCode} ${address?.city}`;

        return {
          headInfo: {
            externalId,
            propertyId,
            title,
            price: price?.toLocaleString('de-DE'),
            priceValue: price ?? 0,
            pricePerSqm,
            currency,
            provision,
            fullAddress,
          },
          ...rest,
        };
      },
    }
  );

  return { headInfo, isLoading, error, isSuccess };
};

export const useExposeDetailsImages = () => {
  const propertyId = useAppSelector(
    (state) => state.exposeDetails.selectedPropertyId
  );
  const {
    propertyImages: images,
    isLoading,
    error,
    isSuccess,
  } = useExposeDetailsQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, ...rest }) => {
        const propertyImages =
          data?.exposeDetails?.propertyDetails?.image || [];

        return {
          propertyImages,
          ...rest,
        };
      },
    }
  );

  return { images, isLoading, error, isSuccess };
};

export const useExposeDetailsPropertyData = () => {
  const propertyId = useAppSelector(
    (state) => state.exposeDetails.selectedPropertyId
  );
  const { propertyData, isLoading, error, isSuccess } = useExposeDetailsQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, ...rest }) => {
        const location = data?.exposeDetails?.location;
        const {
          type,
          livingArea,
          landArea,
          description,
          locationDescription,
          equipmentDescription,
          otherInformation,
        } = data?.exposeDetails?.propertyDetails || {};

        const { buildingYear, modernizationYear } =
          data?.exposeDetails?.propertyDetails?.condition || {};
        const { floor, numberOfFloors } =
          data?.exposeDetails?.propertyDetails?.address || {};
        const { numberOfRooms, numberOfBathrooms, numberOfBedrooms } =
          data?.exposeDetails?.propertyDetails?.area || {};
        const {
          numberOfGarages,
          numberOfDuplexParkingSpaces,
          numberOfUndergroundGarages,
          numberOfOtherParkingSpaces,
          numberOfParkingSpace,
        } = data?.exposeDetails?.propertyDetails?.equipment || {};
        const { numberOfParkingSpaces } =
          data?.exposeDetails?.propertyDetails?.area || {};

        return {
          propertyData: {
            location,
            type,
            buildingYear,
            modernizationYear,
            livingArea,
            landArea,
            floor,
            numberOfRooms,
            numberOfBathrooms,
            numberOfBedrooms,
            numberOfGarages,
            numberOfDuplexParkingSpaces,
            numberOfUndergroundGarages,
            numberOfOtherParkingSpaces,
            numberOfParkingSpace,
            numberOfFloors,
            description,
            locationDescription,
            equipmentDescription,
            otherInformation,
          },
          ...rest,
        };
      },
    }
  );

  return { propertyData, isLoading, error, isSuccess };
};

export const useExposeDetailsTour360 = () => {
  const propertyId = useAppSelector(
    (state) => state.exposeDetails.selectedPropertyId
  );
  const {
    propertyTour: tour360Link,
    isLoading,
    error,
    isSuccess,
  } = useExposeDetailsQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, ...rest }) => {
        const { tour360Link: propertyTour } =
          data?.exposeDetails?.propertyDetails || {};

        return {
          propertyTour,
          ...rest,
        };
      },
    }
  );

  return { tour360Link, isLoading, error, isSuccess };
};

// src/modules/search-profile/pages/expose-details-overlay/components/tabs/overview/value-development/value-development.tsx

export const useExposeDetailsPriceHubble = () => {
  const propertyId = useAppSelector(
    (state) => state.exposeDetails.selectedPropertyId
  );
  const {
    locationData: location,
    poiData: poi,
    poiLastSyncDate: poiLastSync,
    valuationsData: valuations,
    valuationsLastSyncDate: valuationsLastSync,
    locationScoresData: locationScores,
    socialEconomics,
    propertyStatisticsData: propertyStatistics,
    propertyStatisticsLastSyncDate: propertyStatisticsLastSync,
    countryCodeData: countryCode,
    buildingProjectsData: buildingProjects,

    isLoading,
    error,
    isSuccess,
  } = useExposeDetailsQuery(
    {
      id: propertyId,
    },
    {
      selectFromResult: ({ data, ...rest }) => {
        const locationScoresData = data?.exposeDetails?.locationScores;
        const socioEconomics = data?.exposeDetails?.socioEconomics;

        const valuationsData = data?.exposeDetails?.valuations;
        const valuationsLastSyncDate = data?.exposeDetails?.valuationsLastSync;

        const poiData = data?.exposeDetails?.poi;
        const poiLastSyncDate = data?.exposeDetails?.poiLastSync;

        const buildingProjectsData = data?.exposeDetails?.buildingProjects;

        const locationData = data?.exposeDetails?.location;
        const propertyStatisticsData = data?.exposeDetails?.propertyStatistics;
        const propertyStatisticsLastSyncDate =
          data?.exposeDetails?.propertyStatisticsLastSync;
        const countryCodeData = data?.exposeDetails?.countryCode;

        return {
          locationData,
          poiData,
          poiLastSyncDate,
          valuationsData,
          valuationsLastSyncDate,
          socialEconomics: socioEconomics,
          locationScoresData,
          propertyStatisticsData,
          propertyStatisticsLastSyncDate,
          countryCodeData,
          buildingProjectsData,
          ...rest,
        };
      },
    }
  );

  return {
    location,
    poi,
    poiLastSync,
    valuations,
    valuationsLastSync,
    locationScores,
    socialEconomics,
    propertyStatistics,
    propertyStatisticsLastSync,
    countryCode,
    buildingProjects,
    isLoading,
    error,
    isSuccess,
  };
};

export const useSuggestedProperty = ({
  propertyId,
}: {
  propertyId: string;
}) => {
  const { propertyData } = useSuggestedPropertyQuery(
    {
      id: Number(propertyId),
    },
    {
      selectFromResult: ({ data, ...rest }) => {
        const propertyImages = data?.suggestedProperty?.image;
        const { title, price, type, livingArea, landArea, currency } =
          data?.suggestedProperty || {};
        const { buildingYear, modernizationYear } =
          data?.suggestedProperty?.condition || {};
        const { floor, numberOfFloors } =
          data?.suggestedProperty?.address || {};
        const { numberOfRooms, numberOfBathrooms, numberOfBedrooms } =
          data?.suggestedProperty?.area || {};
        const { numberOfGarages } = data?.suggestedProperty?.equipment || {};
        const { numberOfParkingSpaces } = data?.suggestedProperty?.area || {};

        let pricePerSqm;
        if (price && livingArea) {
          pricePerSqm = Number.parseFloat(
            (price / livingArea).toFixed(2).toString()
          );
        }

        return {
          propertyData: {
            images: propertyImages,
            title,
            type,
            livingArea,
            landArea,
            buildingYear,
            modernizationYear,
            floor,
            numberOfFloors,
            numberOfRooms,
            numberOfBathrooms,
            numberOfBedrooms,
            numberOfGarages,
            numberOfParkingSpace: numberOfParkingSpaces,
            price,
            pricePerSqm,
            currency,
          },
          ...rest,
        };
      },
    }
  );

  return { propertyData };
};
