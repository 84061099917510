import { FC, useEffect } from 'react';

import { SearchProfileContainer } from '../search-profile/search-profile-container';
import { CTAContainer } from './search-profile-widget-container-styles';
import { useAppSelector } from '../../../../../common/hooks';
import { CTAButtons } from './cta-buttons/cta-buttons';
import { useFormCloseSP } from '../../../hooks/useFormCloseSP';

const SearchProfileWidgetContainer: FC = () => {
  const isSPCOpen = useAppSelector((state) => state.searchProfile.isSPCOpen);
  const { onFormCloseSP } = useFormCloseSP();
  useEffect(() => {
    return () => {
      onFormCloseSP();
    };
  }, []);

  return (
    <>
      {isSPCOpen ? (
        <SearchProfileContainer />
      ) : (
        <CTAContainer>
          <CTAButtons />
        </CTAContainer>
      )}
    </>
  );
};

export { SearchProfileWidgetContainer };
