import { memo } from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

import { hexToRGB } from '../../../utils/hex-to-rgb';

interface IProps {
  isLoading: boolean;
  isDisabled: boolean;
  color?: string;
  disabledColor?: string;
}

const SpinnerWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translateY(-50%);
  line-height: 0;
  transition: opacity 300ms ease-in-out;
  transition-delay: 150ms;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
`;

const ButtonLoaderBase = ({
  isLoading,
  isDisabled,
  color,
  disabledColor,
}: IProps) => {
  return (
    <SpinnerWrapper isVisible={isLoading ?? false}>
      <ClipLoader
        color={
          isDisabled
            ? disabledColor ?? hexToRGB('#000000', 0.3)
            : color ?? '#fff'
        }
        loading={isLoading}
        size={18}
        speedMultiplier={0.75}
      />
    </SpinnerWrapper>
  );
};

const ButtonLoader = memo(ButtonLoaderBase);

export { ButtonLoader };
