import { ReactComponent as Arrow } from 'assets/navigation/arrow.svg';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { useConfig } from '../../../../../config/useConfig';
import { siteMap } from '../../../../../routes/site-map';
import Icon from '../../../../common/components/ui/icon';
import { useAppSelector } from '../../../../common/hooks';
import useGetLocalization from '../../../../localization/get-localization';
import LngLink from '../../../../localization/lng-link';

const Container = styled.div`
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 12px 27px rgba(0, 0, 0, 0.32);
  padding: 28px 32px;
  width: 342px;
`;

const LinkContainer = styled.div`
  & a:not(:last-child) {
    padding-bottom: 13px;
    border-bottom: 1px solid rgba(0, 48, 94, 0.15);
  }

  & a:not(:first-child) {
    padding-top: 13px;
  }
`;

const Title = styled.h2`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #00305e;
  margin-bottom: 25px;
`;

const LinkItem = styled(LngLink)`
  display: block;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
    transition: opacity 0.3s;
  }
`;

const CTAButton = styled.button`
  margin-top: 24px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  width: 100%;
  color: white;
  background: #4e73f4;
  border-radius: 5px;
`;

const CTAButtonLink = styled(LngLink)`
  text-decoration: none;
  margin-top: 24px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  width: 100%;
  color: white;
  background: #4e73f4;
  border-radius: 5px;
`;

const LinkTitle = styled.h2`
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.02em;
  color: #00305e;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 6px;
`;

const LinkDescription = styled.p`
  font-weight: 400;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.02em;
  color: #00305e;
  opacity: 0.75;
`;

interface InfoBoxProps {
  onCTAClick: () => void;
  isFormVisible: boolean;
  onClose: () => void;
}

const InfoBox = ({ onCTAClick, isFormVisible, onClose }: InfoBoxProps) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);

  const isCHSite = useGetLocalization() === 'de-ch';
  const isATSite = useGetLocalization() === 'de-at';

  // onOffice marketplace iframe URL params
  const urlParams = new URLSearchParams(window.location.search);
  const brokerId = urlParams.get('userid') || '';
  const propertyOnOfficeId = urlParams.get('EstateId') || '';

  const isBrokerView = useAppSelector((state) => state.auth.isBrokerView);

  return (
    <Container>
      <Title>{t('header.authentication.info.title')}</Title>
      <LinkContainer>
        {!isBrokerView && (
          <LinkItem
            to={{ pathname: siteMap.OwnerLandingPage.path }}
            isWithoutLng
          >
            <LinkTitle>
              {t('header.authentication.info.evaluate.title')}
              <Icon
                icon={Arrow}
                width={8}
                height={16}
                color={'#00305E'}
                style={{ opacity: 0.8 }}
              />
            </LinkTitle>
            <LinkDescription>
              {t('header.authentication.info.evaluate.description')}
            </LinkDescription>
          </LinkItem>
        )}
        {/* {!isBrokerView && !isCHSite && !isATSite && ( */}
        {!isBrokerView && (
          <LinkItem
            to={{
              pathname:
                brokerId && propertyOnOfficeId
                  ? `${siteMap.SellerPropertiesPage.pathWithoutParams}?userid=${brokerId}&EstateId=${propertyOnOfficeId}`
                  : siteMap.SellerLandingPage.path,
            }}
            isWithoutLng
          >
            <LinkTitle>
              {t('header.authentication.info.sell.title')}
              <Icon
                icon={Arrow}
                width={8}
                height={16}
                color={'#00305E'}
                style={{ opacity: 0.8 }}
              />
            </LinkTitle>
            <LinkDescription>
              {t('header.authentication.info.sell.description')}
            </LinkDescription>
          </LinkItem>
        )}
        {!isBrokerView && (
          <LinkItem
            to={{ pathname: siteMap.BuyerLandingPage.path }}
            isWithoutLng
          >
            <LinkTitle>
              {t('header.authentication.info.buy.title')}
              <Icon
                icon={Arrow}
                width={8}
                height={16}
                color={'#00305E'}
                style={{ opacity: 0.8 }}
              />
            </LinkTitle>
            <LinkDescription>
              {t('header.authentication.info.buy.description')}
            </LinkDescription>
          </LinkItem>
        )}
        {/* {!isBrokerView && !isCHSite && !isATSite && ( */}
        {!isBrokerView && (
          <LinkItem
            to={{ pathname: siteMap.FinancingLandingPage.path }}
            isWithoutLng
          >
            <LinkTitle>
              {t('header.authentication.info.finance.title')}
              <Icon
                icon={Arrow}
                width={8}
                height={16}
                color={'#00305E'}
                style={{ opacity: 0.8 }}
              />
            </LinkTitle>
            <LinkDescription>
              {t('header.authentication.info.finance.description')}
            </LinkDescription>
          </LinkItem>
        )}
      </LinkContainer>
      {!user && !isFormVisible && (
        <CTAButton onClick={onCTAClick}>
          {t('header.authentication.info.cta-login')}
        </CTAButton>
      )}
      {!user && isFormVisible && (
        <CTAButtonLink absolute onClick={onClose} to={'/'}>
          {t('header.authentication.info.cta-more')}
        </CTAButtonLink>
      )}
    </Container>
  );
};

export default InfoBox;
