import styled from 'styled-components';
import { device } from '../../../../../../../../style/theme';

export const Container = styled.div<{
  isiframe?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media ${({ isiframe }) => (isiframe ? '0' : device.tablet)} {
    overflow: overlay;
  }
`;

export const Row = styled.div<{ direction?: string; isiframe?: boolean }>`
  display: flex;
  flex-direction: ${(props) => props.direction ?? 'row'};

  &:last-of-type {
    margin-top: auto;
    justify-content: flex-start;
    > *:last-of-type {
      margin-left: auto;
    }
  }

  @media ${({ isiframe }) => (isiframe ? '0' : device.tablet)} {
    &:nth-of-type(2) {
      margin-bottom: 32px;
    }

    &:last-of-type {
      margin-top: auto;
    }
  }
`;

export const ContainerInputRadius = styled.div`
  > div {
    justify-content: space-between;
  }
  > div > div:first-child {
    max-width: 128px !important;

    @media ${device.mobile} {
      max-width: 88px !important;
    }
  }
  > div > div:last-child > p {
    text-align: right;
  }
`;
