import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { isNumber } from 'lodash';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Check1Icon } from 'assets/streamline-light/interface-essential/form-validation/check-1.svg';

import {
  MultipleVariantsItemBox,
  TooltipText,
  VariantBox,
  VariantCheckbox,
} from './multiple-financings-styles';
import { setVariantIndex } from '../../../forms/form-financing/redux/financingSlice';
import Icon from '../../../common/components/ui/icon';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { device } from '../../../../style/theme';

interface ITooltipProps {
  text: string;
}

const Container = styled.div`
  margin: 0 8px;
  @media ${device.tablet} {
    margin: 0 12px;

    &:first-child {
      margin: 0 12px 0 16px;
    }
    &:last-child {
      margin: 0 16px 0 12px;
    }
  }
`;

export const Tooltip: FC<ITooltipProps> = ({ children, text }) => {
  return (
    <Container>
      <div data-tip data-for={text}>
        {children}
      </div>
      <ReactTooltip
        // delayHide={1000}
        place="top"
        className={'extraClass'}
        id={text}
        effect="solid"
        type="light"
        borderColor="#d9d9d9"
        border
      >
        <TooltipText>{text}</TooltipText>
      </ReactTooltip>
    </Container>
  );
};

interface ItemProps {
  active: boolean;
  index: number;
  variantIndex: number;
  setEditing: (variant: { index: number; name: string }) => void;
  value: string;
  isTwoVariants?: boolean;
  isHide: boolean;
  onHideVariant(i: number): void;
}

const MultipleVariantsItem: FC<ItemProps> = ({
  value,
  index,
  variantIndex,
  active,
  isHide,
  isTwoVariants,
  onHideVariant,
  setEditing,
}) => {
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <MultipleVariantsItemBox>
      <Tooltip text={value}>
        <VariantBox
          onClick={() => {
            if (active) {
              setEditing({ index: variantIndex, name: value });
            } else if (isNumber(index)) {
              dispatch(setVariantIndex(index));
            }
          }}
          active={active}
        >
          {value}
        </VariantBox>
      </Tooltip>
      {!isMobileSize &&
        (!isHide && isTwoVariants ? (
          <VariantCheckbox isDisabled>
            <Tooltip
              text={t(
                'financing-portal-page.variants-compare.modal.checkbox.disabled.hover'
              )}
            >
              {!isHide && (
                <Icon
                  width={12}
                  height={12}
                  icon={Check1Icon}
                  color={theme.ctaDisabledBackground}
                />
              )}
            </Tooltip>
          </VariantCheckbox>
        ) : (
          <VariantCheckbox onClick={() => onHideVariant(variantIndex)}>
            {!isHide && (
              <Icon
                width={12}
                height={12}
                icon={Check1Icon}
                color={theme.blue}
              />
            )}
          </VariantCheckbox>
        ))}
    </MultipleVariantsItemBox>
  );
};

export { MultipleVariantsItem };
