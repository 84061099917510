import { useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useForgotPasswordMutation } from '../../../../../generated';
import { InputOutsideTopLabel } from '../../../../common/components/form';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import { useAppDispatch } from '../../../../common/hooks';
import { IForgotPasswordForm } from '../../../interfaces';
import {
  setUsername,
  toggleIsForgotPasswordSuccessModalOpen,
  toggleIsForgottenPasswordOpen,
  toggleIsLoginModalOpen,
} from '../../../redux/authSlice';

const Title = styled.h2`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: white;
  margin-bottom: 8px;
`;

const Lighter = styled.span`
  display: block;
  font-weight: 300;
  opacity: 0.95;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 24px;
  color: white;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
`;

const CTAButton = styled.button`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  color: white;
  padding: 10px 14px;
  outline: none;
  background: #4e73f4;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 32px;

  &:hover {
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  &:disabled,
  &:disabled:hover {
    opacity: 1;
    cursor: progress;
    background: rgba(255, 255, 255, 0.5);
  }
`;

interface IProps {
  isWizardForm?: boolean;
  isRedesign?: boolean;
  setIsAuthFormInProgress: (newValue: boolean) => void;
  isAuthFormInProgress: boolean;
}

const ForgotPasswordForm = ({
  isWizardForm,
  setIsAuthFormInProgress,
  isAuthFormInProgress,
}: IProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const methods = useForm<{ username: string }>({
    mode: 'onTouched',
  });

  const [
    forgotPassword,
    { error: forgotPasswordError, data: forgotPasswordData },
  ] = useForgotPasswordMutation();

  const onSubmit: SubmitHandler<IForgotPasswordForm> = useCallback(
    async (formData) => {
      try {
        const { username } = formData;

        dispatch(setUsername(username));
        setIsAuthFormInProgress(true);

        await forgotPassword({
          input: {
            username,
          },
        }).unwrap();
      } catch (error) {
        console.error(error);
      } finally {
        setIsAuthFormInProgress(false);
      }
    },
    [dispatch, forgotPassword, setIsAuthFormInProgress]
  );

  useEffect(() => {
    if (forgotPasswordData) {
      dispatch(toggleIsForgotPasswordSuccessModalOpen(true));
    }

    return () => {
      dispatch(toggleIsForgottenPasswordOpen(false));
      dispatch(toggleIsLoginModalOpen(false));
    };
  }, [dispatch, forgotPasswordData]);

  return (
    <>
      <Title>{t('forgot-password.title')}</Title>
      <FormProvider {...methods}>
        {isWizardForm && (
          <Lighter>{t('valuation.form.forgot-password.description')}</Lighter>
        )}
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {forgotPasswordError?.message && (
            <GlobalError title={t('modal.forgotten-password.error.message')} />
          )}
          {!isWizardForm && (
            <Lighter>{t('forgot-password.form.subtitle')}</Lighter>
          )}
          <InputOutsideTopLabel
            isDisabled={isAuthFormInProgress}
            name="username"
            type="email"
            noLabel
            label="forgot-password.form.input.label.email"
            placeholder={t('forgot-password.form.input.placeholder.email')}
            rules={{
              required: 'register.input.error.required',
            }}
          />
          <CTAButton disabled={isAuthFormInProgress} type={'submit'}>
            {t('forgot-password.restart')}
          </CTAButton>
        </form>
      </FormProvider>
    </>
  );
};

export default ForgotPasswordForm;
