import { FC } from 'react';

import { ReactComponent as Buildings2 } from 'assets/streamline-light/building-construction/buildings/buildings-2.svg';
import { ReactComponent as House } from 'assets/streamline-light/building-construction/houses/house-3.svg';
import { ReactComponent as HouseApartment } from 'assets/streamline-light/building-construction/houses/house-apartment.svg';
import { ReactComponent as PlotIcon } from 'assets/streamline-light/nature-farming/outdoors/outdoors-tree-gate.svg';

import { BoxSelector } from '../../../components/common/box-selector';
import { SEARCH_PROFILE_FORM_KEYS } from '../../constants';
import { BoxType } from '../../../interface';
import { PropertyCode } from '../../../../../generated';
import { useAppSelector } from '../../../../common/hooks';

interface IProps {
  defaultValue?: string;
}

const SelectPropertyCode: FC<IProps> = ({ defaultValue }) => {
  const selectedCountryCode = useAppSelector(
    (state) => state.searchProfile.selectedCountryCode
  );
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  return (
    <BoxSelector
      name={SEARCH_PROFILE_FORM_KEYS.CODE}
      isIframe={isIframe}
      tabletGap={12}
      boxes={[
        {
          id: 'etw',
          boxType: BoxType.iconBox,
          title: 'search-profile.form.first-step.building.apartment',
          value: PropertyCode.Apartment,
          icon: Buildings2,
        },
        {
          id: 'efh',
          boxType: BoxType.iconBox,
          title: 'search-profile.form.first-step.building.house',
          value: PropertyCode.House,
          icon: House,
        },
        {
          id: 'mfh',
          boxType: BoxType.iconBox,
          title: 'search-profile.form.first-step.building.family-house',
          value: PropertyCode.MultiFamilyHouse,
          icon: HouseApartment,
          isDisabled: selectedCountryCode === 'AT',
        },
        {
          id: 'plt',
          boxType: BoxType.iconBox,
          title: 'search-profile.form.first-step.building.plot',
          value: PropertyCode.Plot,
          icon: PlotIcon,
          isDisabled: selectedCountryCode === 'AT',
        },
      ]}
      defaultValue={defaultValue}
    />
  );
};

export { SelectPropertyCode };
