import { CSSProperties, FC, Ref, useContext, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { ThemeContext } from 'styled-components';
import { device } from '../../../../style/theme';
import { ParagraphText } from '../ui/typography';
import { OutsideClickHandler } from '../outside-click-handler';

interface IProps {
  id: string;
  headerText: string;
  descriptionText?: string;
  backText: string;
  acceptText: string;
  maxWidth?: string;
  tabletWidth?: string;
  onClickOutside?: () => void;
  onBeforeDelete?: () => void;
  onDelete?: () => void;
  headerStyle?: CSSProperties;
  tooltipStyle?: CSSProperties;
  tooltipActionsDirection?: 'row' | 'column';
  isDisabled?: boolean;
}

const ContainerChildren = styled.div<{ tabletWidth?: string }>`
  @media ${device.tablet} {
    width: ${(props) => props.tabletWidth ?? 'auto'};
  }
`;

const StyledReactTooltip = styled(ReactTooltip)<{
  style?: CSSProperties;
  maxWidth?: string;
}>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  opacity: 1 !important;
  ${(props) => props.style && { ...props.style }}
`;

const TooltipHeader = styled.h4`
  font-family: 'Source Serif Pro', serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${(props) => props.theme.blue};
  margin-bottom: 12px;
`;

const TooltipDescription = styled(ParagraphText)`
  margin-bottom: 12px;
  text-align: center;
`;

const TooltipActions = styled.div<{ direction: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) =>
    props.direction === 'row' ? 'center' : 'unset'};
  gap: ${(props) => (props.direction === 'row' ? '24px' : 0)};
`;

const TooltipBack = styled.p<{ color?: string }>`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.color ?? props.theme.blue};
  margin-bottom: 12px;
  cursor: pointer;
`;

const TooltipAccept = styled.p<{ isDisabled?: boolean }>`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.ctaBlue};
  cursor: pointer;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
`;

const TooltipConfirmation: FC<IProps> = ({
  id,
  headerText,
  descriptionText,
  backText,
  acceptText,
  onClickOutside,
  onBeforeDelete,
  onDelete = () => {},
  headerStyle,
  maxWidth,
  tabletWidth,
  children,
  tooltipStyle,
  tooltipActionsDirection = 'column',
  isDisabled,
}) => {
  const theme = useContext(ThemeContext);

  const tooltipRef = useRef<
    (ReactTooltip & { tooltipRef: Ref<HTMLElement> }) | null
  >(null);

  return (
    <>
      <ContainerChildren
        tabletWidth={tabletWidth}
        data-tip="tooltip"
        data-for={id}
        onClickCapture={onBeforeDelete}
      >
        {children}
      </ContainerChildren>
      <StyledReactTooltip
        className="tootlip-confirmation"
        ref={tooltipRef}
        place="top"
        event="click"
        id={id}
        effect="solid"
        type="light"
        borderColor="#d9d9d9"
        border
        maxWidth={maxWidth}
        clickable
        style={tooltipStyle}
      >
        <OutsideClickHandler
          display="block"
          onOutsideClick={() => {
            if (onClickOutside) {
              onClickOutside();
            }
            ReactTooltip.hide();
          }}
        >
          <>
            <TooltipHeader style={headerStyle}>{headerText}</TooltipHeader>
            <TooltipDescription content={descriptionText ?? ''} />
            <TooltipActions direction={tooltipActionsDirection}>
              <TooltipBack
                color={acceptText ? theme.blue : theme.ctaBlue}
                onClick={() => {
                  if (tooltipRef.current) {
                    // react-tooltip doesn't hide tooltip when mouse on tooltip
                    // https://github.com/wwayne/react-tooltip/blob/master/src/index.js#L646
                    // checking tooltip ref in mouseOnTooltip function
                    // https://github.com/wwayne/react-tooltip/blob/master/src/index.js#L225
                    tooltipRef.current.tooltipRef = null;
                  }
                  ReactTooltip.hide();
                }}
              >
                {backText}
              </TooltipBack>
              <TooltipAccept
                isDisabled={isDisabled}
                onClick={
                  isDisabled
                    ? undefined
                    : () => {
                        onDelete();
                        ReactTooltip.hide();
                      }
                }
              >
                {acceptText}
              </TooltipAccept>
            </TooltipActions>
          </>
        </OutsideClickHandler>
      </StyledReactTooltip>
    </>
  );
};

export { TooltipConfirmation };
