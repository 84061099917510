import { FC, useState } from 'react';
import styled from 'styled-components';
import ForgotPasswordForm from './forgot-password-form';
import LoginForm from './login-form';

const Container = styled.div`
  width: 342px;
  padding: 28px 32px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    linear-gradient(90.57deg, #002447 4.48%, #00366b 48.29%);
  box-shadow: 0px 12px 27px rgba(0, 0, 0, 0.32);
  display: flex;
  flex-direction: column;
`;

interface IProps {
  setIsAuthFormInProgress: (newValue: boolean) => void;
  isAuthFormInProgress: boolean;
  close: () => void;
}

const FormBox: FC<IProps> = ({
  setIsAuthFormInProgress,
  isAuthFormInProgress,
  close,
}) => {
  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);

  return (
    <Container>
      {isForgotPassword ? (
        <ForgotPasswordForm
          isAuthFormInProgress={isAuthFormInProgress}
          setIsAuthFormInProgress={setIsAuthFormInProgress}
        />
      ) : (
        <LoginForm
          isAuthFormInProgress={isAuthFormInProgress}
          setIsAuthFormInProgress={setIsAuthFormInProgress}
          onForgotPassword={() => setIsForgotPassword(true)}
          close={close}
        />
      )}
    </Container>
  );
};

export default FormBox;
