import { FC, memo, useCallback, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { MainEarner, ValuationType } from '../../../../../generated';
import {
  Form,
  FieldRequirement,
  HiddenSubmitButton,
  SubmitButton,
  InputWrapper,
  FieldRequirementFinancing,
} from './register-form-styles';
import {
  Checkbox,
  InputOutsideTopLabel,
  InputOutsideTopLabelWithSelect,
  InputPassOutsideTopLabel,
} from '../../../../common/components/form';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import {
  FormColumn,
  FormGridWrapper,
  FormRow,
} from '../../../../common/components/form/form-grid';
import { EMAIL_REGEX, PHONE_REGEX } from '../../../../common/constants';
import { useAppSelector } from '../../../../common/hooks';
import { phonePrefixes, REGISTER_FORM_KEYS } from '../../../constants';
import { IIframeType, InputType, IRegisterForm } from '../../../interfaces';
import { useGetCountry } from '../../../../localization/get-country';
import { SelectV2 } from '../../../../forms/components/common/select-v2/select-v2';
import { InputV2 } from '../../../../forms/components/common/input-v2/input-v2';
import { setUserInput } from '../../../redux/authSlice';
import { FIELDS } from '../../../../forms/form-financing/constants';

interface IProps {
  valuationType?: ValuationType;
  errors?: unknown;
  skipPassword?: boolean;
  isSearchProfile?: boolean;
  isRedesign?: boolean;
  isFinancing?: boolean;
  isFullWidthButton?: boolean;
  isButtonBottom?: boolean;
  isFieldRequirement?: boolean;
  isBoldCheckbox?: boolean;
  buttonPosition?: string;

  onSubmit(formData: IRegisterForm): void;
}

const RegisterFormBase: FC<IProps> = ({
  onSubmit,
  valuationType,
  errors,
  skipPassword = false,
  isSearchProfile,
  isRedesign,
  isFinancing,
  isFullWidthButton,
  isButtonBottom,
  isFieldRequirement,
  buttonPosition,
  isBoldCheckbox,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const submitButton = useRef<HTMLButtonElement>(null);
  const methods = useForm<IRegisterForm>();
  const { phonePrefix } = useGetCountry();
  const firstName = useAppSelector((state) => state.auth.userInput?.name);
  const lastName = useAppSelector((state) => state.auth.userInput?.surname);
  const householdNetIncome = useAppSelector(
    (state) => state.auth.userInput?.householdNetIncome
  );
  const age = useAppSelector((state) => state.auth.userInput?.age);
  const mainEarner = useAppSelector(
    (state) => state.auth.userInput?.mainEarner
  );
  const email = useAppSelector((state) => state.auth.userInput?.email);
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const iframeType = useAppSelector((state) => state.auth.iframeType);
  const isIframeTerms =
    iframeType === IIframeType.WIDGET_OWNER_NO_MODAL ||
    iframeType === IIframeType.WIDGET_OWNER_CENTERED;

  const clickSubmitButton = useCallback(() => {
    submitButton?.current?.click();
  }, [submitButton]);

  const submitButtonKey = isFinancing
    ? 'register-form.financing.submit-button'
    : isSearchProfile
    ? 'register-form.search-profile.submit-button-online'
    : valuationType === ValuationType.Online
    ? 'register-form.submit-button-online'
    : 'register-form.submit-button-personal';

  const selectOptions = phonePrefixes.includes(phonePrefix)
    ? phonePrefixes
    : [phonePrefix, ...phonePrefixes];

  const handleChange =
    (name: 'name' | 'surname' | 'householdNetIncome' | 'age' | 'mainEarner') =>
    (value: string | number | boolean) => {
      const input = {
        name: (name === 'name' ? String(value) : firstName) || '',
        surname: (name === 'surname' ? String(value) : lastName) || '',
        phone: '',
        phonePrefix: '',
        email: '',
        password: '',
        confirmPassword: '',
        termsAndConditions: false,
        householdNetIncome:
          (name === 'householdNetIncome'
            ? Number(value)
            : householdNetIncome) || 0,
        age: (name === 'age' ? Number(value) : age) || 0,
        mainEarner:
          (name === 'mainEarner'
            ? (String(value) as MainEarner)
            : (mainEarner as MainEarner)) || '',
      };
      dispatch(setUserInput(input));
    };

  return (
    <FormProvider {...methods}>
      {errors && (
        <GlobalError
          title={t((errors as { message: string })?.message?.split(':')[0])}
        />
      )}
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormGridWrapper>
          <FormRow isIframe={isIframe}>
            <FormColumn>
              <InputOutsideTopLabel
                type={InputType.text}
                name={REGISTER_FORM_KEYS.NAME}
                label="register.form.input.label.name"
                placeholder="register.form.input.placeholder.name"
                rules={{ required: 'register.input.error.required' }}
                defaultValue={firstName}
                isRedesign={isRedesign}
                handleChange={handleChange('name')}
              />
            </FormColumn>
            <FormColumn>
              <InputOutsideTopLabel
                type={InputType.text}
                name={REGISTER_FORM_KEYS.SURNAME}
                label="register.form.input.label.surname"
                placeholder="register.form.input.placeholder.surname"
                rules={{ required: 'register.input.error.required' }}
                defaultValue={lastName}
                isRedesign={isRedesign}
                handleChange={handleChange('surname')}
              />
            </FormColumn>
          </FormRow>
          <FormRow isIframe={isIframe}>
            <FormColumn>
              <InputOutsideTopLabelWithSelect
                type={InputType.number}
                name={REGISTER_FORM_KEYS.PHONE}
                label="register.form.input.label.phone"
                placeholder="register.form.input.placeholder.phone"
                rules={{
                  required: 'register.input.error.required',
                  valueAsNumber: true,
                  pattern: {
                    value: PHONE_REGEX,
                    message: 'register.input.error.phone',
                  },
                }}
                selectWidth="87px"
                hasSelect
                selectOptions={selectOptions}
                selectValue={phonePrefix}
                selectName={REGISTER_FORM_KEYS.PHONE_PREFIX}
                isRedesign={isRedesign}
              />
            </FormColumn>
            <FormColumn>
              <InputOutsideTopLabel
                type={InputType.text}
                name={REGISTER_FORM_KEYS.EMAIL}
                label="register.form.input.label.email"
                placeholder="register.form.input.placeholder.email"
                rules={{
                  required: 'register.input.error.required',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'register.input.error.email',
                  },
                }}
                defaultValue={email}
                isRedesign={isRedesign}
              />
            </FormColumn>
          </FormRow>
          {isFinancing && (
            <>
              <FormRow isIframe={isIframe}>
                <FormColumn>
                  <InputWrapper>
                    <InputV2
                      isSaturatedBorder
                      type={InputType.number}
                      name={REGISTER_FORM_KEYS.HOUSEHOLD_NET_INCOME}
                      autoComplete="off"
                      label={FIELDS.HOUSEHOLD_NET_INCOME.label}
                      placeholder={FIELDS.HOUSEHOLD_NET_INCOME.placeholder}
                      inputAdornment={
                        FIELDS.HOUSEHOLD_NET_INCOME.inputAdornment
                      }
                      rules={FIELDS.HOUSEHOLD_NET_INCOME.rules}
                      isColumn
                      isSeparate
                      isValidateOnBlur
                      handleChangeProp={handleChange('householdNetIncome')}
                    />
                  </InputWrapper>
                </FormColumn>
                <FormColumn>
                  <InputWrapper>
                    <SelectV2
                      isColumn
                      isSaturatedBorder
                      name={REGISTER_FORM_KEYS.MAIN_EARNER}
                      label={FIELDS.MAIN_EARNER.label}
                      placeholder={FIELDS.MAIN_EARNER.placeholder}
                      selectOptions={FIELDS.MAIN_EARNER.options}
                      rules={FIELDS.MAIN_EARNER.rules}
                      handleChangeProp={handleChange('mainEarner')}
                    />
                  </InputWrapper>
                </FormColumn>
              </FormRow>
              <FormRow isIframe={isIframe}>
                <FormColumn>
                  <InputWrapper>
                    <InputV2
                      isSaturatedBorder
                      type={InputType.number}
                      name={REGISTER_FORM_KEYS.AGE}
                      label={FIELDS.AGE.label}
                      placeholder={FIELDS.AGE.placeholder}
                      rules={FIELDS.AGE.rules}
                      autoComplete="off"
                      isColumn
                      isValidateOnBlur
                      handleChangeProp={handleChange('age')}
                    />
                  </InputWrapper>
                </FormColumn>
              </FormRow>
            </>
          )}
          {!skipPassword && (
            <FormRow isIframe={isIframe}>
              <FormColumn>
                <InputPassOutsideTopLabel
                  type={InputType.password}
                  name={REGISTER_FORM_KEYS.PASSWORD}
                  autoComplete="new-password"
                  label="register.form.input.label.password"
                  placeholder="register.form.input.placeholder.password"
                  rules={{
                    required: 'register.input.error.required',
                  }}
                  passwordMasterValue={methods.watch('password')}
                  isRedesign={isRedesign}
                />
              </FormColumn>
              <FormColumn>
                <InputPassOutsideTopLabel
                  type={InputType.password}
                  name={REGISTER_FORM_KEYS.CONFIRM_PASSWORD}
                  autoComplete="new-password"
                  label="register.form.input.label.confirm-password"
                  placeholder="register.form.input.placeholder.confirm-password"
                  rules={{
                    required: 'register.input.error.required',
                  }}
                  passwordMasterValue={methods.watch('password')}
                  skipPattern
                  isRedesign={isRedesign}
                />
              </FormColumn>
            </FormRow>
          )}
          <FormRow isIframe={isIframe}>
            <FormColumn flex="0 0 100%">
              <Checkbox
                name={
                  isFinancing
                    ? REGISTER_FORM_KEYS.TERMS_AND_CONDITIONS_FINANCING
                    : REGISTER_FORM_KEYS.TERMS_AND_CONDITIONS
                }
                isBoldCheckbox={isBoldCheckbox}
                label={
                  isFinancing
                    ? 'register.form.input.label.terms-and-conditions.financing'
                    : isIframeTerms
                    ? 'register.form.owner-portal.input.label.terms-and-conditions.iframe'
                    : isSearchProfile
                    ? 'register.form.buyer-portal.input.label.terms-and-conditions'
                    : valuationType === ValuationType.Online
                    ? 'register.form.input.label.terms-and-conditions.online'
                    : 'register.form.input.label.terms-and-conditions.personal'
                }
                rules={{ required: 'register.input.error.required' }}
              />
            </FormColumn>
            {!isFinancing && (
              <FormColumn flex="0 0 100%">
                {valuationType === ValuationType.Online ? (
                  <Checkbox
                    name={REGISTER_FORM_KEYS.COMMUNICATION_CONSENT}
                    label="register.form.input.label.communication-consent"
                  />
                ) : (
                  <Checkbox
                    name={REGISTER_FORM_KEYS.CREATE_ACCOUNT}
                    innerHTML="register.form.input.label.create-account"
                  />
                )}
              </FormColumn>
            )}
            {isFinancing ? (
              <>
                <FormColumn flex="0 0 100%">
                  <Checkbox
                    name={REGISTER_FORM_KEYS.TERMS_AND_CONDITIONS_FINANCING_2}
                    isBoldCheckbox={isBoldCheckbox}
                    label={
                      'register.form.input.label.terms-and-conditions.financing-2'
                    }
                    link={
                      <>
                        {' '}
                        <a
                          href="https://www.vp-finance.de/datenschutz"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t(
                            'register.form.input.label.terms-and-conditions.financing-2-link'
                          )}
                        </a>
                        .*
                      </>
                    }
                    rules={{ required: 'register.input.error.required' }}
                  />
                </FormColumn>
                <FormColumn flex="0 0 100%">
                  <Checkbox
                    name={REGISTER_FORM_KEYS.COMMUNICATION_CONSENT}
                    label={'register.form.input.label.checkbox.financing'}
                  />
                </FormColumn>
              </>
            ) : null}
          </FormRow>
          {/* <FormRow>
            <FormColumn flex="0 0 100%">
              <Disclaimer>{t('register.form.input.disclaimer')}</Disclaimer>
            </FormColumn>
          </FormRow> */}
        </FormGridWrapper>
        <HiddenSubmitButton
          id="register-form-hidden-submit-button"
          type="submit"
          ref={submitButton}
        />
      </Form>
      {isFieldRequirement &&
        (isFinancing ? (
          <FieldRequirementFinancing isRedesign={isRedesign || isIframe}>
            <span>{t('register.form.required.fields.message.financing')} </span>
            <a
              href="https://home.von-poll.com/de-de/datenschutzinformationenundnutzungsweise"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('register.form.required.fields.message.financing-link')}
            </a>
            .
          </FieldRequirementFinancing>
        ) : (
          <FieldRequirement
            isRedesign={isRedesign || isIframe}
            content={
              isFinancing
                ? t('register.form.required.fields.message.financing')
                : t('register.form.required.fields.message')
            }
          />
        ))}
      <SubmitButton
        onClick={methods.handleSubmit(clickSubmitButton)}
        isiframe={isIframe}
        isFullWidth={isFullWidthButton}
        type="button"
        isButtonBottom={isButtonBottom}
        id="register-form-submit-button"
        label={t(submitButtonKey)}
        isRedesign={isRedesign}
        buttonPosition={buttonPosition}
      />
    </FormProvider>
  );
};

const RegisterForm = memo(RegisterFormBase);

export { RegisterForm };
