import {
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReactComponent as ArrowButtonLeft } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as ArrowButtonRight } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-right-1.svg';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useNavigationPropertiesQuery } from '../../../../../generated';
import { Tooltip } from '../../../../common/components/tooltip';
import Icon from '../../../../common/components/ui/icon';
import { useAppSelector } from '../../../../common/hooks';
import LngLink from '../../../../localization/lng-link';
import {
  Container,
  ItemAddress,
  ItemBox,
  ItemTitle,
  ItemTooltip,
  LeftButton,
  LeftButtonContainer,
  OuterContainer,
  RightButton,
  RightButtonContainer,
} from './property-navigation-styles';
import { siteMap } from '../../../../../routes/site-map';

interface ItemProps {
  active?: boolean;
  _id: string;
  title: string;
  address: string;
}

const Item = ({ _id, active, title, address }: ItemProps): JSX.Element => {
  return (
    <LngLink
      to={`${siteMap.PropertyPage.pathWithoutParams}/${_id}`}
      exact
      absolute
    >
      <ItemBox active={active}>
        <Tooltip
          id={`${_id}-title`}
          position={'bottom'}
          content={<ItemTitle>{title}</ItemTitle>}
        >
          <ItemTooltip>{title}</ItemTooltip>
        </Tooltip>
        <Tooltip
          id={`${_id}-address`}
          position={'bottom'}
          content={<ItemAddress>{address}</ItemAddress>}
        >
          <ItemTooltip>{address}</ItemTooltip>
        </Tooltip>
      </ItemBox>
    </LngLink>
  );
};

const PropertyNavigationBase = (): JSX.Element => {
  const { propertyId: activeId } = useParams<{ propertyId: string }>();
  const ownerId = useAppSelector((state) => state.auth.user?._id);

  const { t } = useTranslation();

  const { data: res, refetch } = useNavigationPropertiesQuery({
    filter: {
      ownerId,
      sortBy: 'createdAt',
      sortOrder: 'desc',
    },
    pagination: {
      offset: 0,
      limit: 0,
    },
  });

  useEffect(() => {
    refetch();
  }, [activeId, refetch]);

  const navigationProperties = useMemo(() => {
    const properties = res?.readProperties?.page || [];
    return properties.map((property, index: number) => {
      const address = property?.propertyData?.location?.address || {};
      return {
        _id: property._id,
        title:
          property.title ||
          property?.propertyPortfolioData?.title ||
          `${t('property-portfolio.property-card.title')} ${
            properties.length - index
          }`,
        address: `${address.street} ${address.houseNumber}, ${address.postCode} ${address.city}`,
      };
    });
  }, [res, t]);

  const scrollContainer = useRef(null);
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);

  const checkForScroll = () => {
    if (!scrollContainer || !scrollContainer.current) return;

    const current = scrollContainer.current! as Element;
    const { clientWidth, scrollWidth, scrollLeft } = current;

    if (scrollLeft > 0) {
      setLeft(true);
    } else {
      setLeft(false);
    }

    if (clientWidth + scrollLeft < scrollWidth) {
      setRight(true);
    } else {
      setRight(false);
    }
  };

  useLayoutEffect(() => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    if (!scrollContainer || !scrollContainer.current) return undefined;

    const current = scrollContainer.current! as Element;
    current.addEventListener('scroll', checkForScroll);
    window.addEventListener('resize', checkForScroll);
    checkForScroll();

    return () => {
      current.removeEventListener('scroll', checkForScroll);
      window.removeEventListener('resize', checkForScroll);
    };
  }, []);

  const scrollHandler = (direction: 'left' | 'right') => {
    if (!scrollContainer || !scrollContainer.current) return;

    const current = scrollContainer.current! as Element;

    current.scrollTo({
      top: 0,
      left:
        direction === 'right'
          ? current.scrollLeft + 250
          : current.scrollLeft - 250,
      behavior: 'smooth',
    });

    checkForScroll();
  };

  useEffect(() => {
    checkForScroll();
  }, [navigationProperties]);

  return (
    <OuterContainer>
      {left && (
        <LeftButtonContainer>
          <LeftButton onClick={() => scrollHandler('left')}>
            <Icon icon={ArrowButtonLeft} width={10} height={10} />
          </LeftButton>
        </LeftButtonContainer>
      )}
      <Container ref={scrollContainer}>
        {navigationProperties.map((item) => {
          return (
            <Item {...item} active={activeId === item._id} key={item._id} />
          );
        })}
      </Container>
      {right && (
        <RightButtonContainer>
          <RightButton onClick={() => scrollHandler('right')}>
            <Icon icon={ArrowButtonRight} width={10} height={10} />
          </RightButton>
        </RightButtonContainer>
      )}
    </OuterContainer>
  );
};

const PropertyNavigation = memo(PropertyNavigationBase);

export { PropertyNavigation };
