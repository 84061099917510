import { ChangeEvent, FC, KeyboardEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterOptions, useController } from 'react-hook-form';
import {
  InputAdornment,
  InputContainer,
  InputField,
  InputHide,
} from './input-range-styles';
import { addCommas, removeNonNumeric } from '../../../utils/number-format';
import { InputMode } from '../../../../common/interfaces';
import { useAppSelector } from '../../../../common/hooks';

interface IProps {
  name: string;
  isSeparate?: boolean;
  rules?: RegisterOptions;
  placeholder?: string;
  defaultValue?: number | string;
  inputAdornment?: string;
  type?: string;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isPlaceholderCenter?: boolean;
  onFocus?: () => void;
  inputMode?: InputMode;
}

const InputMinMax: FC<IProps> = ({
  name,
  isSeparate,
  rules,
  placeholder,
  defaultValue,
  inputAdornment,
  type,
  isDisabled,
  isFullWidth,
  isPlaceholderCenter,
  onFocus,
  inputMode = 'text',
}) => {
  const { t } = useTranslation();
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const {
    field: { ref, onChange, value, ...inputProps },
    formState: { errors },
  } = useController({
    name,
    rules,
    defaultValue: rules?.value ?? defaultValue,
  });

  // separate logic
  const defaultSeparateValue = addCommas(
    removeNonNumeric(isSeparate && defaultValue ? Number(defaultValue) : '')
  );
  const [separateValue, setSeparateValue] = useState(defaultSeparateValue);
  const onSeparateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const eventValue =
      Number.isNaN(e.target.value) || Number.isNaN(e.target.valueAsNumber)
        ? e.target.value
        : e.target.valueAsNumber;

    const eventValueString = String(eventValue)
      .replaceAll('.', '')
      .replaceAll(',', '.');
    const eventValueNumber = Number(eventValueString);

    if (Number.isNaN(eventValueNumber)) {
      return;
    }

    setSeparateValue(addCommas(removeNonNumeric(eventValueString)));
    onChange(eventValueNumber);
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (isSeparate) {
        onChange(
          Number.isNaN(e.target.value) || Number.isNaN(e.target.valueAsNumber)
            ? addCommas(removeNonNumeric(e.target.value))
            : addCommas(removeNonNumeric(e.target.valueAsNumber))
        );
      } else {
        onChange(
          Number.isNaN(e.target.value) || Number.isNaN(e.target.valueAsNumber)
            ? e.target.value
            : e.target.valueAsNumber
        );
      }
    },
    [isSeparate, onChange]
  );

  const formatInput = (e: KeyboardEvent) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === 'e' ||
        e.key === '.' ||
        e.key === '+' ||
        e.key === '-' ||
        e.key === ',';
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  return (
    <InputContainer isFullWidth={isFullWidth} isIframe={isIframe}>
      {isSeparate && (
        <InputHide type="number" id={name} ref={ref} value={value} />
      )}
      <InputField
        id={isSeparate ? `${name} text` : name}
        ref={isSeparate ? null : ref}
        type={isSeparate ? 'text' : type}
        isIframe={isIframe}
        placeholder={t(placeholder ?? '')}
        hasError={errors[name]}
        value={isSeparate ? separateValue : value}
        onChange={isSeparate ? onSeparateChange : handleChange}
        {...(isSeparate ? { inputMode: 'numeric' } : {})}
        isDisabled={isDisabled}
        onFocus={onFocus}
        inputMode={inputMode}
        {...(type === 'number' && {
          onKeyDown: formatInput,
        })}
        isFullWidth={isFullWidth}
        isPlaceholderCenter={isPlaceholderCenter}
        {...inputProps}
      />
      {inputAdornment && <InputAdornment>{inputAdornment}</InputAdornment>}
    </InputContainer>
  );
};

export { InputMinMax };
