import {
  FC,
  JSXElementConstructor,
  KeyboardEventHandler,
  memo,
  ReactElement,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';

import {
  CircleContainer,
  Container,
  Form,
  Headline,
  HeadlineBox,
  StepContainer,
  Title,
} from './modal-form-styles';
import { FormError } from '../../../interface';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import { useDeviceDetector } from '../../../../common/hooks';
import { CircleProgress } from '../../../form-financing/components/containers/financing-containers/financing-info-container/circle-progress/circle-progress';

interface IProps {
  title: string;
  header: string;
  isModalOpen: boolean;
  percent?: number;
  errors?: FormError;

  isHeadline?: boolean;
  formStep: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
}

const ModalFormBase: FC<IProps> = ({
  isModalOpen,
  title,
  header,
  percent,
  errors,
  isHeadline,
  formStep,
}) => {
  const { t } = useTranslation();
  const { isIphone } = useDeviceDetector();

  const handleKeyDown: KeyboardEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      if (e.keyCode === 13) e.preventDefault();
    },
    []
  );

  return (
    <Container isFixTop={isIphone()}>
      {isHeadline && (
        <HeadlineBox>
          <Headline>{t(header)}</Headline>
        </HeadlineBox>
      )}
      <Title isShort={!isModalOpen}>{t(title)}</Title>

      {isNumber(percent) && (
        <CircleContainer>
          <CircleProgress percent={percent} />
        </CircleContainer>
      )}

      <Form autoComplete="off" onKeyDown={handleKeyDown}>
        {errors?.message && (
          <GlobalError
            title={t(errors?.message?.split(':')[0].toLowerCase())}
          />
        )}
        <StepContainer>{formStep}</StepContainer>
      </Form>
    </Container>
  );
};

const ModalForm = memo(ModalFormBase);

export { ModalForm };
