import { FC, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { device } from '../../../../style/theme';
import {
  InputOutsideTopLabel,
  InputPassOutsideTopLabel,
} from '../../../common/components/form';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { FormColumn, FormRow } from '../../../common/components/form/form-grid';
import { MainButton, TextButton } from '../../../common/components/ui/buttons';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { ILoginForm } from '../../interfaces';
import { toggleIsForgottenPasswordOpen } from '../../redux/authSlice';

interface IProps {
  onSubmit: (formData: ILoginForm) => void;
  errors?: unknown;
  isLoading?: boolean;
  isRedesign?: boolean;
  isButtonBottom?: boolean;
  onForgotPasswordClick?: () => void;
  isFullWidthButton?: boolean;
}

const Form = styled.form<{ isiframe?: boolean; isButtonBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: ${({ isButtonBottom }) => (isButtonBottom ? '100%' : 'auto')};
  @media ${({ isiframe }) => (isiframe ? '0' : device.tablet)} {
    position: relative;
    height: 100%;
  }
`;

const ForgottenPasswordContainer = styled.div<{ isRedesign?: boolean }>`
  margin: ${({ isRedesign }) => (isRedesign ? '24px 0' : '20px 0 0 0')};
  display: flex;
  ${({ isRedesign }) => isRedesign && 'justify-content: center;'}

  @media ${device.tablet} {
    ${({ isRedesign }) => isRedesign && 'align-items: flex-end;'}
  }
`;

const SubmitButton = styled(MainButton)<{ isRedesign?: boolean }>`
  position: ${({ isRedesign }) => (isRedesign ? 'static' : 'absolute')};
  right: 0;
  bottom: 0;
  align-items: flex-end;
  text-transform: uppercase;
  justify-content: center;

  @media ${device.tablet} {
    width: 100%;
  }

  @media ${device.mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const UppercaseTextButton = styled(TextButton)`
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  align-self: flex-start;
`;

const LoginFormWizard: FC<IProps> = ({
  onSubmit,
  errors,
  isRedesign,
  isButtonBottom,
  onForgotPasswordClick,
  isFullWidthButton,
}) => {
  const methods = useForm<ILoginForm>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const onForgottenPassword = () => {
    dispatch(toggleIsForgottenPasswordOpen(true));
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={methods.handleSubmit(onSubmit)}
        isiframe={isIframe}
        isButtonBottom={isButtonBottom}
      >
        {errors && (
          <GlobalError
            title={t((errors as { message: string })?.message?.split(':')[0])}
          />
        )}
        <FormRow>
          <FormColumn flex="0 0 100%">
            <InputOutsideTopLabel
              name="userAuthenticationKey"
              type="text"
              label="email-phone.label"
              placeholder={t('email-phone.placeholder')}
              rules={{
                required: true,
              }}
              isRedesign={isRedesign}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn flex="0 0 100%">
            <InputPassOutsideTopLabel
              name="password"
              type="password"
              label="register.form.input.label.password"
              placeholder="register.form.input.placeholder.password"
              rules={{ required: true }}
              passwordMasterValue={methods.watch('password')}
              isRedesign={isRedesign}
            />
          </FormColumn>
        </FormRow>
        <FormRow flexGrow={1} alignItems="flex-end">
          <FormColumn flex="0 0 100%">
            <ForgottenPasswordContainer isRedesign={isRedesign || isIframe}>
              <UppercaseTextButton
                color={themeContext.blue}
                $forceActive
                type="button"
                onClick={onForgotPasswordClick || onForgottenPassword}
              >
                {t('modal.login.forgotten-password')}
              </UppercaseTextButton>
            </ForgottenPasswordContainer>
            <SubmitButton
              isRedesign={isRedesign || isIframe}
              isFullWidth={isFullWidthButton}
              label={t('modal.login.submit')}
            />
          </FormColumn>
        </FormRow>
      </Form>
    </FormProvider>
  );
};

export { LoginFormWizard };
