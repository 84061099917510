import { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckCircle } from 'assets/streamline-light/interface-essential/form-validation/check-circle-1.svg';
import { ThemeContext } from 'styled-components';
import { Container, InfoItemContainer } from './useful-info-styles';
import Icon from '../../../../common/components/ui/icon';

interface IItemProps {
  keyPhrase: string;
}

interface IProps {
  isRegionalPage?: boolean;
  isIframe?: boolean;
  isShort?: boolean;
  keyPhrases: string[];
}

const InfoItem: FC<IItemProps> = ({ keyPhrase }) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  return (
    <InfoItemContainer>
      <Icon
        height={16}
        width={16}
        icon={CheckCircle}
        color={themeContext.blue}
      />
      <div lang="de">{t(keyPhrase)}</div>
    </InfoItemContainer>
  );
};

const UsefulInfoBase: FC<IProps> = ({
  isRegionalPage,
  isIframe,
  isShort,
  keyPhrases,
}) => {
  return (
    <Container
      isRegionalPage={isRegionalPage}
      isIframe={isIframe}
      isShort={isShort}
    >
      {keyPhrases.map((phrase) => (
        <InfoItem key={phrase} keyPhrase={phrase} />
      ))}
    </Container>
  );
};

const UsefulInfo = memo(UsefulInfoBase);

export { UsefulInfo };
