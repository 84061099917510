import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, Slide } from 'react-toastify';
import styled, { ThemeProvider } from 'styled-components';
import { CSSProperties, FC } from 'react';
import { Helmet } from 'react-helmet-async';

import CSSReset from '../../../../style/reset';
import { defaultTheme, device } from '../../../../style/theme';
import { Footer } from './footer';
import { CollapseHeader } from './header/collapse-header';
import { Header } from './header/header';
import { TimifyModal } from '../../../timify/components/timify-modal';
import { LocalizationContainer } from '../../../localization/localization-container';
import { useGetEvaluationWidgetVersion } from '../../../forms/form-property-valuation-wizard-v2/hooks/useGetWidgetVersion';
import { ValuationModalV2 } from '../../../forms/form-property-valuation-wizard-v2/components/containers/property-valuation-containers/valuation-modal/valuation-modal';
import useGetLocalization from '../../../localization/get-localization';
import { useIframeLogic } from '../../../landing/hooks/useIframeLogic';
import { useAppSelector } from '../../hooks';
import { FinancingModal } from '../../../forms/form-financing/components/containers/financing-containers/financing-modal/financing-modal';
import { IIframeType } from '../../../auth/interfaces';

interface IProps {
  children: JSX.Element;
  containerStyles?: CSSProperties;
  contentNoMargin?: boolean;
  hasAuthHeader?: boolean;
  clip?: boolean;
  isError?: boolean;
}

const Container = styled.div<{ $clip?: boolean }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.$clip &&
    `
    overflow: clip;

    @media ${device.tablet} {
      overflow: hidden;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
    }
  `}
`;

const Content = styled.div<{ contentNoMargin?: boolean }>`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  ${(props) =>
    !props.contentNoMargin &&
    `
    padding-bottom: 88px;
  `}
  @media ${device.tablet} {
    max-width: 100%;
    padding-bottom: 0;
  }
`;

const Layout: FC<IProps> = ({
  children,
  contentNoMargin,
  containerStyles,
  hasAuthHeader,
  clip,
  isError,
}) => {
  const { isRedesignVersion } = useGetEvaluationWidgetVersion();
  const lang = useGetLocalization();
  const { iframeType, isIframe } = useAppSelector((state) => state.auth);
  const isBenefits = iframeType === IIframeType.BENEFIT;
  useIframeLogic();

  return (
    <ThemeProvider theme={defaultTheme}>
      <LocalizationContainer isError={isError}>
        <Helmet htmlAttributes={{ lang }} />
        <CSSReset />
        {isIframe && (
          <Content contentNoMargin={!isBenefits}>{children}</Content>
        )}
        {!isIframe && (
          <>
            <Container $clip={clip} style={containerStyles}>
              <Header hasAuthHeader={hasAuthHeader} />
              <CollapseHeader />
              <Content contentNoMargin={contentNoMargin}>{children}</Content>
              <Footer />
            </Container>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              transition={Slide}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <TimifyModal />
          </>
        )}
        {isRedesignVersion && <ValuationModalV2 />}
        <FinancingModal />
      </LocalizationContainer>
    </ThemeProvider>
  );
};

export { Layout };
