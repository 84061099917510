import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import FormBox from './form-box';
import InfoBox from './info-box';

const ModalPlacement = document.querySelector('#modal-root') as Element;

const Backdrop = styled.div`
  background: rgba(0, 0, 0, 0.15);
  position: fixed;
  backdrop-filter: blur(4.5px);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  z-index: 2000;
`;

interface BackdropComponentProps {
  close: () => void;
}

const BackdropComponent = ({ close }: BackdropComponentProps) => {
  return createPortal(<Backdrop onMouseOver={close} />, ModalPlacement);
};

const Content = styled.div<{ isMobile?: boolean }>`
  position: absolute;
  top: 61px;
  right: 30px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  overflow: hidden;

  ${(props) =>
    props.isMobile &&
    `
    left: 0;
    right: 0;
    justify-content: center;
    z-index: 3000;
    width: 100%;
    min-height: 50vh;
  `}
`;

interface AuthModalProps {
  isOpen: boolean;
  close: () => void;
  showForm: boolean;
  isMobile?: boolean;
}

const AuthModal: FC<AuthModalProps> = ({
  isOpen,
  close,
  showForm,
  isMobile,
}) => {
  const [isAuthFormInProgress, setIsAuthFormInProgress] =
    useState<boolean>(false);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsFormVisible(showForm);
  }, [showForm]);

  const toggleBodyScroll = (scrollState: boolean) => {
    document.body.style.overflow = scrollState ? 'auto' : 'hidden';
  };

  useEffect(() => {
    toggleBodyScroll(!isOpen);
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <Content isMobile={isMobile}>
        {isMobile && showForm ? null : (
          <InfoBox
            isFormVisible={isFormVisible}
            onClose={close}
            onCTAClick={() => setIsFormVisible(true)}
          />
        )}
        {isFormVisible && (
          <FormBox
            isAuthFormInProgress={isAuthFormInProgress}
            setIsAuthFormInProgress={setIsAuthFormInProgress}
            close={close}
          />
        )}
      </Content>
      <BackdropComponent close={isAuthFormInProgress ? () => {} : close} />
    </>
  );
};

export default AuthModal;
