import { FC, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useForgotPasswordMutation } from '../../../../generated';
import { device } from '../../../../style/theme';
import { InputOutsideTopLabel } from '../../../common/components/form';
import { GlobalError } from '../../../common/components/form/error/global-error';
import { FormColumn, FormRow } from '../../../common/components/form/form-grid';
import { MainButton } from '../../../common/components/ui/buttons';
import { ParagraphText } from '../../../common/components/ui/typography';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { IForgotPasswordForm } from '../../interfaces';
import {
  setUsername,
  toggleIsForgottenPasswordOpen,
  toggleIsLoginModalOpen,
  toggleIsForgotPasswordSuccessModalOpen,
} from '../../redux/authSlice';

interface IProps {
  isWizardForm?: boolean;
  isRedesign?: boolean;
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  @media ${device.tablet} {
    position: relative;
    height: 100%;
  }
`;

const SubmitButton = styled(MainButton)<{ isRedesign?: boolean }>`
  margin: ${({ isRedesign }) => (isRedesign ? '12px 0 4px 0' : '24px 0 4px 0')};
  justify-content: center;
  ${({ isRedesign }) => isRedesign && 'width: 100%;'}

  ${({ isRedesign, fluid }) =>
    !fluid &&
    !isRedesign &&
    `
    position: absolute;
    right: 0;
    bottom: 0;
    align-items: flex-end;
    text-transform: uppercase;
  `}
`;

const LastFormRow = styled(FormRow)`
  @media ${device.tablet} {
    margin-top: auto;
  }
`;

const Paragraph = styled(ParagraphText)<{ isRedesign?: boolean }>`
  margin: 0 0 16px 0;
  text-align: ${({ isRedesign }) => (isRedesign ? 'left' : 'center')};
`;

const ForgotPasswordForm: FC<IProps> = ({ isWizardForm, isRedesign }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isIframe = useAppSelector((state) => state.auth.isIframe);
  const methods = useForm<{ username: string }>({
    mode: 'onTouched',
  });

  const [
    forgotPassword,
    { error: forgotPasswordError, data: forgotPasswordData, isLoading },
  ] = useForgotPasswordMutation();

  const onSubmit: SubmitHandler<IForgotPasswordForm> = useCallback(
    async (formData) => {
      try {
        const { username } = formData;

        dispatch(setUsername(username));
        await forgotPassword({
          input: {
            username,
          },
        }).unwrap();
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, forgotPassword]
  );

  useEffect(() => {
    if (forgotPasswordData) {
      dispatch(toggleIsForgotPasswordSuccessModalOpen(true));
    }

    return () => {
      dispatch(toggleIsForgottenPasswordOpen(false));
      dispatch(toggleIsLoginModalOpen(false));
    };
  }, [dispatch, forgotPasswordData]);

  if (isLoading) return <>...Loading</>;

  return (
    <FormProvider {...methods}>
      {isWizardForm && (
        <Paragraph
          isRedesign={isRedesign || isIframe}
          content={t('valuation.form.forgot-password.description')}
        />
      )}
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        {forgotPasswordError?.message && (
          <GlobalError title={t('modal.forgotten-password.error.message')} />
        )}
        {!isWizardForm && (
          <Paragraph content={t('forgot-password.form.subtitle')} />
        )}
        <FormRow>
          <FormColumn flex="0 0 100%">
            <InputOutsideTopLabel
              name="username"
              isRedesign={isRedesign}
              type="email"
              label="forgot-password.form.input.label.email"
              placeholder={t('forgot-password.form.input.placeholder.email')}
              rules={{
                required: true,
              }}
            />
          </FormColumn>
        </FormRow>
        <LastFormRow flexGrow={1} alignItems="flex-end">
          <FormColumn flex="0 0 100%">
            <SubmitButton
              fluid={!isWizardForm}
              isRedesign={isRedesign || isIframe}
              label={t('modal.forgotten-password.submit')}
            />
          </FormColumn>
        </LastFormRow>
      </Form>
    </FormProvider>
  );
};

export { ForgotPasswordForm };
