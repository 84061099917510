import { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GlobalError } from '../../../../../common/components/form/error/global-error';
import { FormError } from '../../../../interface';
import { ISearchProfileForm } from '../../../interface';
import { getSearchStepTitle } from '../../../../utils/getStepTitle';
import { Progress } from '../../../../components/common/progress';
import { MobileBack } from '../../common/mobile-back';
import { FirstStep } from './steps/first-step/first-step';
import { SecondStep } from './steps/second-step/second-step';
import { Form, Title } from './search-profile-form-styles';
import { useAppSelector } from '../../../../../common/hooks';

interface IProps {
  activeFormStep: number;
  stepsCount: number;
  submitForm?: boolean;
  errors?: FormError;

  regionalFirstStepNext?: () => void;
  regionalSecondStepBack?: () => void;
  onSubmit?: (formData: ISearchProfileForm, ownerId?: string) => Promise<void>;
  lastStepCallback?(): void;
  lastStepLoginCallback?(): void;
}

const SearchProfileFormBase: FC<IProps> = ({
  activeFormStep,
  stepsCount,
  submitForm,
  onSubmit,
  lastStepCallback,
  regionalFirstStepNext,
  regionalSecondStepBack,
  errors,
}) => {
  const methods = useForm<ISearchProfileForm>({
    defaultValues: {},
  });
  const { t } = useTranslation();
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const mobileTitle = t(
    'buyer-landing-page.hero-slider.financing-advice.title'
  ).toUpperCase();

  return (
    <>
      {!isIframe && <MobileBack title={mobileTitle} />}
      <FormProvider {...methods}>
        <Form
          isiframe={isIframe}
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.keyCode === 13) e.preventDefault();
          }}
        >
          <Progress activeBullet={activeFormStep} bullets={stepsCount} />
          {errors?.message && (
            <GlobalError
              title={t(errors?.message?.split(':')[0].toLowerCase())}
            />
          )}
          <Title content={t(getSearchStepTitle(activeFormStep))} />
          {activeFormStep === 0 && (
            <FirstStep regionalFirstStepNext={regionalFirstStepNext} />
          )}
          {activeFormStep === 1 && (
            <SecondStep
              regionalSecondStepBack={regionalSecondStepBack}
              submitForm={submitForm}
              onSubmit={onSubmit}
              lastStepCallback={lastStepCallback}
            />
          )}
        </Form>
      </FormProvider>
    </>
  );
};

const SearchProfileForm = memo(SearchProfileFormBase);

export { SearchProfileForm };
