import { useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  CreatePropertyInput,
  LoginResponse,
  UserType,
  useSignInMutation,
} from '../../../../../generated';
import { client } from '../../../../../services/graphql/graphql';
import {
  InputOutsideTopLabel,
  InputPassOutsideTopLabel,
} from '../../../../common/components/form';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { useCreateTemporaryProperty } from '../../../../forms/form-property-valuation-wizard/hooks/useCreateTemporaryProperty';
import { IValuationForm } from '../../../../forms/form-property-valuation-wizard/interface';
import {
  resetValuationWizard,
  toggleRegisterForm,
} from '../../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { resetSPC } from '../../../../forms/form-search-profile/redux/searchProfileSlice';
import { parseValuationInput } from '../../../../forms/utils/parse-valuation-input';
import { ILoginForm, IRegisterForm } from '../../../interfaces';
import {
  login,
  setAnonymousUser,
  setResendVerificationAccountEmail,
  setUserInput,
  setUsername,
  setUserState,
  toggleIsLoginModalOpen,
  toggleIsRegisterModalOpen,
  toggleIsResendVerificationAccountModalOpen,
} from '../../../redux/authSlice';
import { useLngHistoryPush } from '../../../../localization/lng-history-push';
import { siteMap } from '../../../../../routes/site-map';

const Title = styled.h2`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: white;
  margin-bottom: 16px;
`;

const Lighter = styled.span`
  font-weight: 300;
  opacity: 0.95;
  -webkit-font-smoothing: antialiased;
`;

const FormWrapper = styled.div`
  flex: 1;

  .error-message {
    width: 100%;
    color: ${(props) => props.theme.red};
    background-color: #f0e9e9;
    border: solid 1px ${(props) => props.theme.lightRed};
    border-radius: 3px;
    margin-bottom: 16px;
    margin-top: 16px;
    padding: 12px;
  }
`;

const InputBoxWithMargin = styled.div`
  margin-bottom: 15px;
`;

const ButtonsBox = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextButton = styled.a<{ disabled: boolean }>`
  font-family: Roboto, sans-serif;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.2px;
  font-size: 12px;
  font-weight: 400;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  ${(props) =>
    props.disabled &&
    `
    &, &:hover {
      opacity: 0.8;
      cursor: not-allowed;
    }
  `}
`;

const CTAButton = styled.button`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  color: white;
  padding: 10px 14px;
  outline: none;
  background: #4e73f4;
  border-radius: 5px;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  &:disabled,
  &:disabled:hover {
    opacity: 1;
    cursor: progress;
    background: rgba(255, 255, 255, 0.5);
  }
`;

const SecondaryBtn = styled.button`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #ffffff;

  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  background: transparent;
  height: 35px;
  width: 100%;
  margin-top: auto;
  cursor: pointer;
`;

interface IProps {
  onForgotPassword: () => void;
  setIsAuthFormInProgress: (newValue: boolean) => void;
  isAuthFormInProgress: boolean;
  close: () => void;
}

const LoginForm = ({
  onForgotPassword,
  setIsAuthFormInProgress,
  isAuthFormInProgress,
  close,
}: IProps) => {
  const methods = useForm<ILoginForm>({
    mode: 'onTouched',
  });
  const {
    location: { pathname },
  } = useHistory();
  const lngHPush = useLngHistoryPush();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { slug } = useParams<{ slug?: string }>();
  const [inputPassword, setInputPassword] = useState('');

  const [validationError, setValidationError] = useState('');

  const userInput = useAppSelector((state) => state.valuationWizard.userInput);
  const selectedAddress = useAppSelector(
    (state) => state.valuationWizard.selectedAddress
  );
  const regionalBrokerId = useAppSelector(
    (state) => state.valuationWizard.regionalBrokerId
  );
  const [signIn, { data: signInData, error, isLoading }] = useSignInMutation();
  const { createTemporaryProperty } = useCreateTemporaryProperty();

  const onSubmit: SubmitHandler<ILoginForm> = useCallback(
    async (formData) => {
      const { userAuthenticationKey, password } = formData;

      setValidationError('');
      setInputPassword(password);

      try {
        setIsAuthFormInProgress(true);
        await signIn({
          input: {
            userAuthenticationKey,
            password,
            requestAccessToken: true,
            regionalBrokerId,
            slug,
          },
        });
      } catch (error_) {
        console.error(error_);
      } finally {
        setIsAuthFormInProgress(false);
      }
    },
    [regionalBrokerId, setIsAuthFormInProgress, signIn, slug]
  );

  useEffect(() => {
    if (signInData) {
      const loginResponse = signInData.signIn as LoginResponse;
      if (loginResponse?.jwt?.token) {
        client.setHeader(
          'Authorization',
          `Bearer ${loginResponse?.jwt?.token ?? ''}`
        );
        batch(() => {
          dispatch(login(loginResponse));
          dispatch(setUserState(loginResponse.user.userState));
          dispatch(setAnonymousUser(false));
          dispatch(resetValuationWizard());
          dispatch(resetSPC());
        });
        const user = loginResponse?.user;
        const isOverviewPage = pathname.split('/')?.[2]?.length === 0;
        if (isOverviewPage) {
          if (user?.userType?.includes(UserType.Seller)) {
            lngHPush(siteMap.SellerLandingPage.path);
          } else if (user?.userType?.includes(UserType.Owner)) {
            lngHPush(siteMap.OwnerLandingPage.path);
          } else if (user?.userType?.includes(UserType.Buyer)) {
            lngHPush(siteMap.BuyerLandingPage.path);
          } else if (user?.userType?.includes(UserType.Financier)) {
            lngHPush(siteMap.FinancingLandingPage.path);
          } else {
            lngHPush(siteMap.SellerLandingPage.path);
          }
        }
      } else if (loginResponse.user && !loginResponse.jwt) {
        batch(() => {
          dispatch(toggleRegisterForm(true));
          dispatch(toggleIsRegisterModalOpen(true));
          dispatch(setUserState(loginResponse.user.userState));
          dispatch(
            setUserInput({
              name: loginResponse.user.name,
              surname: loginResponse.user.surname,
              phonePrefix: '',
              phone: loginResponse.user.phone,
              email: loginResponse.user.email,
              password: inputPassword,
              confirmPassword: inputPassword,
              termsAndConditions: loginResponse.user.termsAndConditions,
            })
          );
          dispatch(setUsername(loginResponse.user.username));
        });
      }
      dispatch(toggleIsLoginModalOpen(false));
      close();
    }
  }, [dispatch, signInData, inputPassword]);

  // TODO: move this logic to another component
  useEffect(() => {
    if (signInData && userInput && selectedAddress) {
      const loginResponse = signInData.signIn as LoginResponse;
      try {
        const valuationInput = parseValuationInput(
          userInput as IValuationForm,
          selectedAddress
        ) as CreatePropertyInput;

        createTemporaryProperty({
          valuationInput,
          userId: loginResponse.user._id,
        }).catch((error_) => console.error(error_));
      } catch (temporaryError) {
        console.error(temporaryError);
      }
    }
  }, [createTemporaryProperty, selectedAddress, signInData, userInput]);

  useEffect(() => {
    if (error?.message?.includes('error.user.account.not.confirmed')) {
      const values = methods.getValues();
      dispatch(toggleIsResendVerificationAccountModalOpen(true));
      dispatch(setResendVerificationAccountEmail(values.userAuthenticationKey));
    }
  }, [error?.message]);

  return (
    <>
      <Title>{t('login-form.title')}</Title>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {validationError && <GlobalError title={t(validationError)} />}
          {!validationError && error?.message && (
            <GlobalError title={t(error?.message?.split(':')[0])} />
          )}
          <FormWrapper>
            <InputBoxWithMargin>
              <InputOutsideTopLabel
                name="userAuthenticationKey"
                type="text"
                label="email-phone.label"
                placeholder={'email-phone.placeholder'}
                noLabel
                isDisabled={isAuthFormInProgress}
                rules={{
                  required: 'register.input.error.required',
                }}
              />
            </InputBoxWithMargin>

            <InputPassOutsideTopLabel
              name="password"
              type="password"
              label="register.form.input.label.password"
              placeholder="register.form.input.placeholder.password"
              rules={{ required: 'register.input.error.required' }}
              noLabel
              isDisabled={isAuthFormInProgress}
              passwordMasterValue={methods.watch('password')}
            />

            <ButtonsBox>
              <TextButton
                disabled={isAuthFormInProgress}
                onClick={isAuthFormInProgress ? () => {} : onForgotPassword}
              >
                {t('login-form.forgot-password')}
              </TextButton>
              <CTAButton disabled={isAuthFormInProgress} type={'submit'}>
                {t('button.login-now')}
              </CTAButton>
            </ButtonsBox>
          </FormWrapper>
        </form>
        {/* <SecondaryBtn>{t('login-form.create-account')}</SecondaryBtn> */}
      </FormProvider>
    </>
  );
};

export default LoginForm;
