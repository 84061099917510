import { FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { ValuationLoader } from 'modules/forms/components/common/loader';
import { BenefitsSection } from '../../components/overview-page/benefits-section/benefits-section';
import { FunctionsSection } from '../../components/overview-page/functions-section/functions-section';

import {
  ContentWithPadding,
  IFrameContainer,
} from './buyer-landing-page-styles';
import {
  BuyerLandingPage as BuyerLP,
  BuyerLpSection,
  BuyerLpSectionType,
  useReadBuyerLandingPageQuery,
} from '../../../../generated';
import { Card } from '../../../common/components/ui/card';
import { Hero } from '../../../common/components/ui/hero';
import { HeroSlider } from '../../../common/components/ui/hero-slider/hero-slider';
import { GlobalLoader } from '../../../common/components/ui/loaders/global-loader';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { Mapbox } from '../../../map/components/mapbox';
import { Appointments } from '../../components/appointment/appointment';
import { LngRedirect } from '../../../localization/lng-redirect';
import { SearchProfileWidgetContainer } from '../../../forms/form-search-profile/components/containers/search-profile-widget-container/search-profile-widget-container';
import { useFormOpenSP } from '../../../forms/form-search-profile/hooks/useFormOpenSP';
import { useSearchProfilesQuery } from '../../../../services/graphql/enhanced';
import { toggleIsHighlight } from '../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { getAttributesFromMeta } from '../../utils/get-attributes-from-meta';
import { Benefits } from '../../components/benefit/benefits';
import { Faqs } from '../../components/faqs/faqs';
import { VirtualTours } from '../../components/buyer-lp/virtual-tour/virtual-tours';
import { SuggestedProperties } from '../../components/buyer-lp/suggested-properties/components/suggested-properties';
import { sortByOrderIndex } from '../../utils/sort-by-order-index';
import { siteMap } from '../../../../routes/site-map';
import { toggleIsLoginModalOpen } from '../../../auth/redux/authSlice';
import { locales } from '../../../localization/constants';
import useGetLocalization from '../../../localization/get-localization';
import { SearchProfileContainer } from '../../../forms/form-search-profile/components/containers/search-profile/search-profile-container';

interface BuyerLandingPageResponse {
  data: { readBuyerLandingPage: BuyerLP };
  isLoading: boolean;
}

interface IProps {
  isOpenWidget?: boolean;
}

const BuyerLandingPage: FC<IProps> = ({ isOpenWidget }) => {
  const dispatch = useAppDispatch();
  const lang = useGetLocalization();
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  const urlParams = new URLSearchParams(window.location.search);
  const searchProfileId = urlParams.get('searchProfileId') || '';
  const openWidget = urlParams.get('openWidget') === 'true';

  const { onFormOpenSP } = useFormOpenSP({
    cb: () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => {
        dispatch(toggleIsHighlight(true));
      }, 600);
      setTimeout(() => {
        dispatch(toggleIsHighlight(false));
      }, 2100);
    },
  });

  // Fetch landing page details
  const { data: buyerLPData, isLoading: isLandingPageLoading } =
    useReadBuyerLandingPageQuery<BuyerLandingPageResponse>({
      locale: lang,
    });

  const isSPCOpen = useAppSelector((state) => state.searchProfile.isSPCOpen);
  const selectedAddress = useAppSelector(
    (state) => state.searchProfile.selectedAddress?.place_name
  );
  const activeStep = useAppSelector((state) => state.searchProfile.activeStep);
  const userId = useAppSelector((state) => state.auth.user?._id);
  const isAnonymousUser = useAppSelector((state) => state.auth.isAnonymousUser);
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const {
    data: userSearchProfiles,
    isLoading: areUserSearchProfilesLoading,
    isFetching: areUserSearchProfilesFetching,
  } = useSearchProfilesQuery(
    { ownerId: userId },
    { skip: !userId || isAnonymousUser }
  );

  useEffect(() => {
    if (searchProfileId) {
      dispatch(toggleIsLoginModalOpen(true));
    }

    // open widget logic
    if (openWidget || isIframe || isOpenWidget) {
      onFormOpenSP();
    }
  }, []);

  if (
    isLandingPageLoading ||
    areUserSearchProfilesFetching ||
    areUserSearchProfilesLoading
  ) {
    // return <GlobalLoader />;
    return <ValuationLoader maxWidth="500px" />;
  }

  // If the user has properties do not let him hit the landing page
  if (userSearchProfiles?.searchProfiles.length && !isIframe) {
    const id =
      userSearchProfiles?.searchProfiles[
        userSearchProfiles?.searchProfiles.length - 1
      ]._id;
    return (
      <LngRedirect
        to={`${siteMap.SearchProfilePage.pathWithoutParams}/${
          searchProfileId || id
        }${openWidget ? '?openWidget=true' : ''}`}
      />
    );
  }

  if (!buyerLPData) {
    return <Redirect to={siteMap.OwnerLandingPage.path} />;
  }

  const {
    readBuyerLandingPage: {
      imageAlt,
      imageUrl,
      metaDescription,
      metaKeywords,
      sections,
      metaTitle,
      subtitle,
      text,
      title,
    },
  } = buyerLPData;

  const sortedSections = sortByOrderIndex(sections ?? []);
  const benefitSectionIndex = sortedSections.findIndex(
    (section) => section.type === BuyerLpSectionType.Benefit
  );

  const path = isOpenWidget
    ? siteMap.BuyerLandingPageWidget.path
    : siteMap.BuyerLandingPage.path;

  return (
    <>
      <Helmet>
        <title>{metaTitle || t('buyer-portal.title')}</title>
        <meta name="title" content={metaTitle ?? ''} />
        <meta name="description" content={metaDescription ?? ''} />
        <meta property="og:title" content={metaTitle ?? ''} />
        <meta property="og:description" content={metaDescription ?? ''} />
        {imageUrl && <meta property="og:image" content={String(imageUrl)} />}
        <meta property="twitter:title" content={metaTitle ?? ''} />
        <meta property="twitter:description" content={metaDescription ?? ''} />
        {imageUrl && (
          <meta property="twitter:image" content={String(imageUrl)} />
        )}
        {metaKeywords?.map((item) => {
          const metaObject = getAttributesFromMeta(item);
          return (
            <meta
              key={metaObject.name}
              name={metaObject.name}
              content={metaObject.content}
            />
          );
        })}
        {locales.map(({ hrefLang, locale }) => (
          <link
            key={path}
            rel="alternate"
            href={`https://home.von-poll.com/${locale}${path}`}
            hrefLang={hrefLang}
          />
        ))}
        <link
          rel="canonical"
          href={`https://home.von-poll.com/${lang}${path}`}
        />
      </Helmet>
      {isIframe && (
        <IFrameContainer>
          <SearchProfileContainer />
        </IFrameContainer>
      )}
      {!isIframe && (
        <>
          <Hero
            {...(!isSPCOpen && {
              imgSrc: imageUrl ?? '',
              imgAlt: imageAlt ?? '',
            })}
            isWithoutMargin
          >
            <>{!isMobileSize && <Mapbox isVisible={isSPCOpen} />}</>
            <HeroSlider
              showAddressBar={activeStep > 0}
              address={selectedAddress}
              addressBarTitle={t('your.neighborhood')}
            >
              <Card
                header={title ?? ''}
                titleFirstLine={subtitle ?? ''}
                showAddressBar={activeStep > 0}
                content={text ?? ''}
              />
              <Card
                titleType="h2"
                titleFirstLine={t(
                  'buyer-landing-page.hero-slider.financing-advice.title'
                )}
                content={t(
                  'buyer-landing-page.hero-slider.financing-advice.first-card.content'
                )}
                showAddressBar
              />
              <Card
                titleType="h3"
                titleFirstLine={t(
                  'buyer-landing-page.hero-slider.financing-advice.title'
                )}
                content={t(
                  'buyer-landing-page.hero-slider.financing-advice.second-card.content'
                )}
                showAddressBar
              />
              {/* register */}
              <Card
                titleType="h4"
                titleFirstLine={t(
                  'buyer-landing-page.hero-slider.financing-advice.title'
                )}
                content={t(
                  'buyer-landing-page.hero-slider.financing-advice.third-card.content'
                )}
                showAddressBar
              />
              {/* login */}
              <Card
                titleType="h5"
                titleFirstLine={t(
                  'buyer-landing-page.hero-slider.financing-advice.title'
                )}
                content={t(
                  'buyer-landing-page.hero-slider.financing-advice.fourth-card.content'
                )}
                showAddressBar
              />
            </HeroSlider>
            <SearchProfileWidgetContainer />
          </Hero>
          <ContentWithPadding benefitSectionIndex={benefitSectionIndex + 1}>
            {sortedSections?.map((section: BuyerLpSection) => (
              <Fragment key={section.orderIndex}>
                {section.type === BuyerLpSectionType.Benefit && (
                  <Benefits
                    section={section}
                    ctaHandler={onFormOpenSP}
                    isSlider
                  />
                )}

                {section.type === BuyerLpSectionType.BuyerBenefit && (
                  <BenefitsSection bgWhite section={section} />
                )}

                {section.type === BuyerLpSectionType.BuyerFunction && (
                  <FunctionsSection bgWhite section={section} />
                )}

                {section.type === BuyerLpSectionType.Faq && (
                  <Faqs section={section} />
                )}

                {section.type === BuyerLpSectionType.Appointment && (
                  <Appointments rpSection={section} />
                )}

                {section.type === BuyerLpSectionType.VirtualTour && (
                  <VirtualTours section={section} isSlider={isMobileSize} />
                )}

                {section.type === BuyerLpSectionType.PropertiesOnFocus && (
                  <SuggestedProperties title={section.title} />
                )}
              </Fragment>
            ))}
          </ContentWithPadding>
        </>
      )}
    </>
  );
};

export { BuyerLandingPage };
