import { FC, memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { Container, Title } from './modal-header-styles';
import { CloseButton } from '../../../../common/components/modal/close-button';
import { BackButton } from '../../../../common/components/modal/back-button';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';

interface IProps {
  openWidget?: boolean;
  activeStep: number;
  title: string;
  isUpperCase: boolean;

  onClose: () => void;
  onBackClick: () => void;
}

const ModalHeaderBase: FC<IProps> = ({
  openWidget,
  title,
  isUpperCase,
  activeStep,
  onClose,
  onBackClick,
}) => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const themeContext = useContext(ThemeContext);

  const isFirstStep = useMemo(() => activeStep === 0, [activeStep]);

  return (
    <Container>
      {!(openWidget && isFirstStep) && (
        <CloseButton
          handleClose={onClose}
          color={isMobileSize ? themeContext.white : themeContext.blue}
          position="right"
        />
      )}
      {!isFirstStep && (
        <BackButton
          handleBack={onBackClick}
          color={isMobileSize ? themeContext.white : themeContext.blue}
          position="left"
        />
      )}
      <Title>{isUpperCase ? t(title).toUpperCase() : t(title)}</Title>
    </Container>
  );
};

const ModalHeader = memo(ModalHeaderBase);

export { ModalHeader };
