import { useEffect } from 'react';
import {
  resetSPC,
  setErrors,
  setRedirect,
  toggleLoading,
} from '../redux/searchProfileSlice';
import { siteMap } from '../../../../routes/site-map';
import { FormError } from '../../interface';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useConvertSearchProfileMutation } from '../../../../services/graphql/enhanced';

const useConvertSP = (): void => {
  const dispatch = useAppDispatch();
  const lngHPush = useLngHistoryPush();

  const isRedirect = useAppSelector((state) => state.searchProfile.isRedirect);
  const selectedAddress = useAppSelector(
    (state) => state.searchProfile.selectedAddress
  );
  const owner = useAppSelector((state) => state.auth.user?._id);
  const userInput = useAppSelector((state) => state.searchProfile.userInput);

  const [convertSearchProfile] = useConvertSearchProfileMutation();

  useEffect(() => {
    const convertAsync = async () => {
      try {
        dispatch(toggleLoading(true));

        const searchProfileResponse = await convertSearchProfile({
          ownerId: owner as string,
        }).unwrap();

        if (searchProfileResponse?.convertSearchProfile?._id) {
          dispatch(resetSPC());
          lngHPush(siteMap.BuyerLandingPage.path);
        }
      } catch (error) {
        dispatch(setErrors(error as FormError));
      } finally {
        dispatch(toggleLoading(false));
      }
    };
    if (isRedirect) {
      dispatch(setRedirect(false));
      convertAsync();
    }
  }, [
    dispatch,
    isRedirect,
    userInput,
    selectedAddress,
    owner,
    convertSearchProfile,
    lngHPush,
  ]);
};

export { useConvertSP };
