import styled from 'styled-components';
import { isString } from 'lodash';

import { device } from '../../../../style/theme';

export const Container = styled.div<{
  isIframe?: boolean;
  isOverflowVisible?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow-y: ${({ isOverflowVisible }) =>
    isOverflowVisible ? 'visible' : 'auto'};

  @media ${({ isIframe }) => (isIframe ? device.mobileIframe : device.tablet)} {
    & > :nth-child(1) {
      order: 1;
    }
    & > :nth-child(2) {
      order: 3;
    }
    & > :nth-child(3) {
      order: 2;
    }
    & > :nth-child(4) {
      order: 4;
    }
  }
`;

export const FormContainer = styled.div<{
  isRegionalPage?: boolean;
  tabletPadding?: string;
}>`
  flex-grow: 1;
  padding: ${({ isRegionalPage }) => (isRegionalPage ? 24 : 64)}px;

  @media ${device.tablet} {
    padding: ${({ tabletPadding }) =>
      isString(tabletPadding) ? tabletPadding : '16px'};
  }
`;

export const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 152px 88px;

  @media ${device.tablet} {
    padding: 16px;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-family: 'Source Serif Pro';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ theme }) => theme.blue};
  margin-bottom: 24px;

  @media ${device.tablet} {
    text-align: center;
    margin-bottom: 16px;
  }
`;
