import { FC, memo, useCallback, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { FlyToInterpolator } from 'react-map-gl';
import { easeCubic } from 'd3-ease';

import { PropertyCode } from '../../../../../../../../generated';
import { GlobalError } from '../../../../../../../common/components/form/error/global-error';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks';
import { useIsMobileSize } from '../../../../../../../common/hooks/useIsMobileSize';
import {
  changeViewport,
  setMarkers,
  setSettings,
} from '../../../../../../../map/redux/mapSlice';
import { SEARCH_PROFILE_FORM_KEYS } from '../../../../../constants';
import { BorderButton } from '../../../../../../../common/components/ui/buttons';

import {
  nextStepAction,
  resetSPC,
} from '../../../../../redux/searchProfileSlice';
import { NextButton } from '../../../../../../components/common/next-button';
import { Europe } from '../../../../../../../map/constants/points-of-interest';
import { Input } from '../../../../../../components/common/input/input';
import { Container, ContainerInputRadius, Row } from './first-step-styles';
import { fields } from './constants';
import { IProps } from './interface';
import { InputCityAddress } from '../../../../common/input-city-address';
import { SelectPropertyCode } from '../../../../common/select-property-code';

const FirstStepBase: FC<IProps> = ({ regionalFirstStepNext }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  const [proceedToNextStep, setProceedToNextStep] = useState(false);
  const [invalidAddress, setInvalidAddress] = useState(false);
  const storedAddress = useAppSelector(
    (state) => state.searchProfile.selectedAddress
  );

  const [city, postCode, locality, neighborhood, code, placeName, radius] =
    useWatch({
      name: [...fields, SEARCH_PROFILE_FORM_KEYS.RADIUS],
    });

  const setFinalAddress = useCallback(() => {
    if (regionalFirstStepNext) {
      regionalFirstStepNext();
    }
    if (radius) {
      dispatch(setSettings({ radius }));
    }
    setProceedToNextStep(true);
    const pin = {
      longitude: storedAddress?.center?.[0] || 0,
      latitude: storedAddress?.center?.[1] || 0,
    };
    batch(() => {
      dispatch(
        changeViewport({
          longitude: +(pin?.longitude ?? 0) + 0.005,
          latitude: +(pin?.latitude ?? 0) - 0.001,
          zoom: 15,
          transitionDuration: 'auto',
          transitionInterpolator: new FlyToInterpolator({
            speed: 1.8,
          }),
          transitionEasing: easeCubic,
        })
      );
      dispatch(setMarkers([pin]));
      dispatch(nextStepAction());
    });
  }, [regionalFirstStepNext, radius, storedAddress?.center, dispatch]);

  const onPrevButtonClick = useCallback(
    (e) => {
      e.preventDefault();
      batch(() => {
        dispatch(
          changeViewport({
            ...Europe,
            transitionDuration: 1000,
          })
        );
        dispatch(resetSPC());
      });
    },
    [dispatch]
  );

  return (
    <Container>
      <Row>
        <SelectPropertyCode />
      </Row>
      {city &&
        !invalidAddress &&
        ![
          PropertyCode.Apartment,
          PropertyCode.House,
          PropertyCode.MultiFamilyHouse,
          PropertyCode.Plot,
        ].includes(code) && (
          <GlobalError
            title={t('valuation.form.first-step.code-is-required')}
            topMargin
          />
        )}
      <Row direction="column">
        <InputCityAddress
          city={city}
          placeName={placeName}
          postCode={postCode}
          locality={locality}
          neighborhood={neighborhood}
          proceedToNextStep={proceedToNextStep}
          setProceedToNextStep={setProceedToNextStep}
          invalidAddress={invalidAddress}
          setInvalidAddress={setInvalidAddress}
        />
        <ContainerInputRadius>
          <Input
            name={SEARCH_PROFILE_FORM_KEYS.RADIUS}
            label="search-profile.form.first-step.radius"
            sublabel="(1 - 200km)"
            defaultValue={'1'}
            placeholder="search-profile.form.first-step.radius-max-placeholder"
            type="number"
            rules={{
              required: 'register.input.error.required',
              min: {
                value: 1,
                message: 'search-profile.form.first-step.radius-min-error',
              },
              max: {
                value: 200,
                message: 'search-profile.form.first-step.radius-max-error',
              },
              valueAsNumber: true,
              setValueAs: (v) => Number.parseInt(v, 10),
            }}
            inputAdornment="km"
            inputMode={'numeric'}
          />
        </ContainerInputRadius>
      </Row>
      <Row>
        {!isMobileSize && !regionalFirstStepNext && (
          <BorderButton
            id="step-1"
            label={t('button.back')}
            onClick={onPrevButtonClick}
          />
        )}
        <NextButton
          watchedKeys={[...fields, SEARCH_PROFILE_FORM_KEYS.RADIUS]}
          id="exit"
          label={t('button.next-step')}
          buttonCallback={setFinalAddress}
          outerDisable={invalidAddress}
          isSearchProfile
        />
      </Row>
    </Container>
  );
};

const FirstStep = memo(FirstStepBase);

export { FirstStep };
