import { FC, memo, MouseEvent, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FlatButton } from '../../../common/components/ui/buttons';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { IValuationForm } from '../../form-property-valuation-wizard/interface';
import { updateFormStateAction as updateFormStateActionSearchProfile } from '../../form-search-profile/redux/searchProfileSlice';

import {
  nextStepAction,
  updateFormStateAction,
} from '../../form-property-valuation-wizard/redux/valuationWizardSlice';
import {
  nextStepAction as nextStepActionV2,
  updateFormStateAction as updateFormStateActionV2,
} from '../../form-property-valuation-wizard-v2/redux/valuationWizardV2Slice';
import {
  nextStepAction as nextStepActionFinancing,
  updateFormStateAction as updateFormStateActionFinancing,
} from '../../form-financing/redux/financingSlice';

const FORBIDDEN_VALUES = new Set([undefined, null, Number.NaN, '']);

interface IProps {
  watchedKeys: string[];
  label: string;
  submitForm?: boolean;
  buttonCallback?(): void;
  outerDisable?: boolean;
  id?: string;
  isEvaluationV2?: boolean;
  isFinancing?: boolean;
  isSearchProfile?: boolean;
}

const NextButtonBase: FC<IProps> = ({
  watchedKeys,
  label,
  submitForm,
  buttonCallback,
  outerDisable,
  isEvaluationV2,
  isFinancing,
  isSearchProfile,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const { trigger } = useFormContext();
  const { t } = useTranslation();
  const { watch } = useFormContext();

  let watchedStepState = watch(watchedKeys);
  // let watchedStepState = useWatch({
  //   name: watchedKeys,
  // });

  const isIframe = useAppSelector((state) => state.auth.isIframe);

  if (watchedKeys && watchedKeys.length > 0) {
    watchedStepState = watchedStepState.slice(0, watchedKeys.length);
  }

  const isDisabled = watchedStepState.some((e) => FORBIDDEN_VALUES.has(e));

  const onClickHandler = useCallback(
    async (e?: MouseEvent) => {
      e?.preventDefault();

      const isFormValid = await trigger(watchedKeys);

      if (isFormValid) {
        if (submitForm) {
          return;
        }

        const formState = watchedKeys.reduce(
          (acc, _, i) => ({
            ...acc,
            [watchedKeys[i]]: watchedStepState[i],
          }),
          {}
        ) as Partial<IValuationForm>;

        if (isEvaluationV2) {
          dispatch(updateFormStateActionV2(formState));
        } else if (isFinancing) {
          dispatch(updateFormStateActionFinancing(formState));
        } else if (isSearchProfile) {
          dispatch(updateFormStateActionSearchProfile(formState));
        } else {
          dispatch(updateFormStateAction(formState));
        }

        if (buttonCallback) {
          buttonCallback();
          return;
        }

        if (isEvaluationV2) {
          dispatch(nextStepActionV2());
        } else if (isFinancing) {
          dispatch(nextStepActionFinancing());
        } else {
          dispatch(nextStepAction());
        }
      }
    },
    [
      buttonCallback,
      dispatch,
      isEvaluationV2,
      isFinancing,
      submitForm,
      trigger,
      watchedKeys,
      watchedStepState,
    ]
  );

  const onEnterKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== 'Enter') return;

      if (onClickHandler) {
        onClickHandler();
      }
    },
    [onClickHandler]
  );
  useEffect(() => {
    window.addEventListener('keydown', onEnterKeyDown);

    return () => {
      window.removeEventListener('keydown', onEnterKeyDown);
    };
  }, [onEnterKeyDown]);

  return (
    <FlatButton
      label={isEvaluationV2 || isFinancing ? t(label).toUpperCase() : label}
      onClick={onClickHandler}
      isDisabled={isDisabled || outerDisable}
      isIframe={isIframe}
      {...rest}
    />
  );
};

const NextButton = memo(NextButtonBase);

export { NextButton };
