import styled from 'styled-components';
import { hexToRGB } from '../../../utils/hex-to-rgb';
import { MainButton } from '../buttons';
import { device } from '../../../../../style/theme';

interface IAnimationProps {
  $height: number;
  $padding: number;
}

export const Container = styled.div<IAnimationProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.9;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #e5e9ec;
  overflow: hidden;
  height: ${(props) => props.$height}px;
  min-height: ${(props) => props.$height}px;
  padding: ${(props) => props.$padding}px 12px ${(props) => props.$padding}px
    24px;
`;

export const AddressContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  flex-grow: 1;
  min-width: 10px;
`;

export const AddressContainer = styled.div`
  display: flex;
`;

export const AddressInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & i {
    min-width: 24px;
  }
`;

export const Title = styled.h4`
  margin: 0 0 4px 0;
  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  &&&& {
    color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  }
`;

export const Address = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &&&& {
    color: ${(props) => props.theme.blue};
  }
`;

export const StarsImageContainer = styled.div`
  overflow: hidden;
  display: flex;
  //position: absolute;
  margin-right: -11px;
  top: 0;
  min-width: 203px;
  height: 100%;
  right: 0;
`;

export const StarsImage = styled.img`
  height: 100%;
`;

export const RadiusInfo = styled.div`
  margin: 0 8px;
  padding: 0 8px;
  border-radius: 10px;
  border: solid 1px #d9d9d9;
  background-color: #fff;
  > p {
    opacity: 0.5;
    font-family: Roboto, sans-serif;
    font-size: 10px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.5px;
    color: ${(props) => props.theme.blue};
  }
`;

export const NewPropertiesCountInfo = styled.div`
  padding: 0 8px;
  border-radius: 10px;
  background-color: #74a257;
  display: flex;
  align-items: center;
  > p {
    font-family: Roboto, sans-serif;
    font-size: 10px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    //line-height: 1.6;
    letter-spacing: 0.5px;
    color: ${(props) => props.theme.white};
  }
`;

export const FullScreenMapButton = styled(MainButton)`
  justify-content: center;
  padding: 21px;
  width: fit-content;
  border-radius: 0;
  position: absolute;
  top: 0;
  right: 0;
  i {
    margin: 0 !important;
  }
  display: none;

  @media ${device.tablet} {
    display: flex;
  }
`;
